import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { PrimaryButton } from "../components/general/Buttons"
import PatientsTable from "../components/tables/PatientsTable"
import { sortDataList } from "../components/general/Utils"
import { useTranslation } from 'react-i18next'


function getDiagnosis(diagnoses, diagnosis_id) {
    for (const diagnosis of diagnoses) {
        if (diagnosis["id"] === diagnosis_id) return diagnosis
    }
    throw new Error(`Error. Diagnosis not found in database with ID: ${diagnosis_id}`)
}

function YourPatients({accessToken, user}) {
    const { t } = useTranslation()
    const [yourPatients, setYourPatients] = useState([])
    const [patientTableUpdates, setPatientTableUpdates] = useState(false)

    async function fetchData() {
        var subscriptions
        var patientDiagnoses
        var diagnoses
        var patientSsn
        var promises = []
        var fetchedYourPatients = []
        fetch(`${process.env.REACT_APP_FAST_API_URI}/users/get_subscriptions/user_id?uuid=${user.id}`, {
                    headers: {
                    Authorization: `Bearer ${accessToken}`,
                    },
                })
            .then(response => {
                if (response.ok) {
                    console.log("response", response)
                    return response.json()
                } else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            })
            .then(subscriptionData => {
                subscriptions = subscriptionData
                // Get diagnoses
                return fetch(`${process.env.REACT_APP_FAST_API_URI}/algorithm/get_diagnosis_information/?language=${t("languages.queryParam")}`,{
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    }
                })
            })
            .then(response => {
                if (response.ok) {
                    console.log("response", response)
                    return response.json()
                } else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            })
            .then(responseData => {
                diagnoses = responseData
                for (const patient of subscriptions) {
                    patientSsn = patient["social_security_number"]
                    // Add to a list of promises returned by calling the fetch function
                    promises.push(fetch(`${process.env.REACT_APP_FAST_API_URI}/patients/get_patient/${patientSsn}`,{
                        method: "GET",
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            "Content-Type": "application/json"
                        }
                    }))
                }
                return Promise.all(promises)
            })
            .then(responses => {
                // Catch responses and verify that the status of them all is ok
                return Promise.all(responses.map(response => {
                    if(response.ok) {
                        console.log("response", response)
                        return response.json()
                    }
                    else {
                        throw new Error(`Error. Response code: ${response.status}`)
                    }
                }))
            })
            .then(responseData => {
                for (const [index, patient] of responseData.entries()){
                    patientDiagnoses = patient["diagnosis_ids"].map(diagnosis_id => getDiagnosis(diagnoses, diagnosis_id))
                    patientDiagnoses = sortDataList(patientDiagnoses, "description")

                    fetchedYourPatients.push(
                        {
                        "patientSsn": patient["social_security_number"],
                        "patientFirstName": patient["patient_first_name"],
                        "patientLastName": patient["patient_last_name"],
                        "diagnoses": patientDiagnoses
                        }
                    )
                } 
                setYourPatients(fetchedYourPatients)
            })
            .catch(error => {
                console.log("error:: ")
                console.log(error)
            })
        }

    useEffect(() => {
        fetchData()
    }, [patientTableUpdates, accessToken])

    return (
        <div className="flexbox-page">
            <h1>{t("pages.YourPatients.h1")}</h1>
            <PatientsTable accessToken={accessToken} rows={yourPatients} user={user} setPatientTableUpdates={setPatientTableUpdates}/>
            <PrimaryButton label={t("pages.YourPatients.PrimaryButton.label")} to="/add_patient" component={Link} state={{patientSsn: null}}/>
        </div>
    )
}


export default YourPatients