import Logo from './Logo'
import footer_img from './../../images/footer.png'
import './../../styles/Navigation.css'

export default function Footer() {
    let dateString = getCurrentDateTime()
    return <footer>
        <img
            src={footer_img}
            alt="background image"
            className="footer-img"
        />
        <div className="footer-content">
            <Logo />
            {dateString}
        </div>
    </footer>
}

function getCurrentDateTime(){
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    
    return <p>{year}-{month<10?`0${month}`:`${month}`}-{date<10?`0${date}`:`${date}`} {hours<10?`0${hours}`:`${hours}`}:{minutes<10?`0${minutes}`:`${minutes}`}</p>
}