import { createContext, useState } from 'react'

const initialState = {}

const AlertContext = createContext({
  ...initialState,
  setAlert: () => {},
})

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({})
  
  const closeAlert = (event, reason) => {
    setAlert({})
  }

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
        closeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export default AlertContext
