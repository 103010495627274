import { structionSsn, upperCaseName } from './Utils'
import { useTranslation } from 'react-i18next'

export default function PatientInformationBox({ patientSsn, patientSex, patientFirstName, patientLastName, patientNameHeader }) {
    const { t } = useTranslation()
    return (
        <div>
            {patientNameHeader ? <h1>{upperCaseName(patientFirstName, patientLastName)}</h1> : <h2>{upperCaseName(patientFirstName, patientLastName)}</h2>}
            <p>
                {structionSsn(patientSsn)},{' '}
                {
                    patientSex==='M' ? 
                    t("components.general.PatientInformationBox.sexdisplay.M") : 
                    (patientSex==='F' ? 
                    t("components.general.PatientInformationBox.sexdisplay.F") : 
                    t("components.general.PatientInformationBox.sexdisplay.O"))
                }
            </p>
        </div>            
    )
}