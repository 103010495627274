import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Form from '../components/forms/Form'
import Dropdown from '../components/general/Dropdown'
import TranslateIcon from '@mui/icons-material/Translate';

import SearchField from '../components/general/SearchField'
import { PrimaryButton, SecondaryButton, SimpleButton } from '../components/general/Buttons'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, ButtonGroup } from '@mui/material'
import useAlert from '../components/alerts/useAlert'
import AlertOnPage from '../components/alerts/AlertOnPage'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { description: 'The Shawshank Redemption', id: 'The Shawshank Redemption' },
  { description: 'The Godfather', id: 'Godfather' },
  { description: 'The Godfather: Part II', id: 'Godfather: Part II' },
  { description: 'The Dark Knight', id: 'Knight' },
  { description: '12 Angry Men', id: 'Angry' },
  { description: "Schindler's List", id: 'Schindler' },
  { description: 'Pulp Fiction', id: 'Fiction' },
  {
    description: 'The Lord of the Rings: The Return of the King',
    id: 'Return',
  },
  { description: 'The Good, the Bad and the Ugly', id: 'Good' },
  { description: 'Fight Club', id: 'Fight' },
  {
    description: 'The Lord of the Rings: The Fellowship of the Ring',
    id: 'Fellowship',
  }
  ]

function FieldTypes() {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN


  const { control, handleSubmit, register, formState: { errors }, watch, setValue } = useForm()

  const { setAlert } = useAlert()
  const [localAlert, setLocalAlert] = useState({})

  const birthDateDefault = watch("ssn")

  React.useEffect(() =>{
    if (/^(19|20)\d{6}[-]?\d{4}$/i.test(birthDateDefault)){
      setValue('birthDate', `${birthDateDefault.substring(0,4)}-${birthDateDefault.substring(4,6)}-${birthDateDefault.substring(6,8)}`)
    }
  })

  const dropdownItems = [{id: 1, description: "One"},{id: 2, description: "Two"},{id: 3, description: "Three"}]

  const [dropdownValue, setDropdownValue] = React.useState(dropdownItems[0].id)

  const dropdownHandleChange = (event) => {
    console.log(event.target.value)
    setDropdownValue(event.target.value)
  }

  const { getAccessTokenWithPopup } = useAuth0()
  function consentHandleClick(){
    getAccessTokenWithPopup({
      audience: `https://userapi.com`,
      scope: "offline_access"
    })
    .then(receivedToken => {
        console.log(receivedToken)
    })
  }

  function getUuidConsentHandleClick(){
    getAccessTokenWithPopup({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    })
    .then(receivedToken => {
      console.log(receivedToken)
    })
  }

  const handleAlertClick = (alertType) => {
    if (alertType === "success") {
      setAlert(prevState => ({
        ...prevState,
        open: true,
        severity: alertType,
        content: alertType,
        action: null
      }))
    } else {
      setLocalAlert(prevState => ({
        ...prevState,
        open: true,
        severity: alertType,
        content: alertType,
        action: null
      }))
    }
  }

  const { t } = useTranslation()

  const inputFields = [
    {
      fieldLabel: "Personnummer",
      inputType: "Textfield",
      name: "ssn",
      label: "Personnummer",
      type: "text",
      pattern: /^(19|20)\d{6}[-]?\d{4}$/i,
      patternMessage: "Error: Format YYYYMMDD-NNNN"
    },
    {
      fieldLabel: "Födelsedatum",
      inputType: "Date",
      label: "Födelsedatum",
      name: "birthDate",
      required: true,
      readOnly: /^(19|20)\d{6}[-]?\d{4}$/i.test(birthDateDefault)
    },
    {
      fieldLabel: "Labb datum och tid",
      inputType: "Date time",
      label: "Datum och tid",
      name: "labTimeDate",
      required: true,
    },
    {
      fieldLabel: "Vikt",
      inputType: "Textfield",
      name: "weight",
      label: "Vikt",
      type: "number",
      min: 0,
      minMessage: "Vikt måste vara större än 0",
      endAdornment: "kg"
    },
    {
      fieldLabel: "Select one option",
      inputType: "Dropdown (select)",
      label: "Välj en",
      name: "option",
      required: false,
      canBeEmpty: true,
      items: [
        {"id": 20, "description": "Twenty"},
        {"id": 21, "description": "Twenty one"},
        {"id": 22, "description": "Twenty two"}
      ]
    },
    {
      fieldLabel: "Select multiple options",
      inputType: "Dropdown (select multiple)",
      label: "Välj en eller flera",
      name: "options",
      required: false,
      items: [
        "Option one",
        "Option two",
        "Option three"
      ]
    },
    {
      fieldLabel: "Radio button field",
      inputType: "Radio",
      name: "radio",
      required: false,
      items: [
        {"id": 1, "description": "Option one"},
        {"id": 2, "description": "Option two"},
        {"id": 3, "description": "Option three"},
        {"id": 5, "description": "Option 4"},
        {"id": 6, "description": "Option 5"},
        {"id": 7, "description": "Option 6"}
      ]
    },
    {
      fieldLabel: "Switch field",
      inputType: "Switch (true/false)",
      name: "test_switch",
      defaultValue: false
    },
    {
      fieldLabel: "Autocomplete field",
      inputType: "Autocomplete - select multiple",
      name: "movies",
      label: "Favorite movies",
      required: false,
      placeholder: "Add movie",
      items: top100Films
    }
  ]

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <PrimaryButton label="Set up concent" onClick={consentHandleClick}/>
      <PrimaryButton label="Set up concent uuid" onClick={getUuidConsentHandleClick}/>
      <h1>Example Page for components</h1>
      <h2>General components</h2>
      <h3>PrimaryButton</h3>
      <PrimaryButton label="Primary Button"/>
      <h3>SecondaryButtons</h3>
      <SecondaryButton label="Secondary Button" />
      <SecondaryButton label="Secondary red Button" red={true} />
      <SimpleButton label="Simple button" />
      <h3>Dropdown</h3>
      <Dropdown 
          id="test-dropdown"
          value={dropdownValue}
          handleChange={dropdownHandleChange}
          items={dropdownItems}
      />
      
      <h2>SearchField</h2>
      <SearchField/>
      <h2>Form</h2>
      <Form
          title="Test form"
          inputFields={inputFields}
          onSubmit={(data) => console.log(data)}
          control={control}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          submitButtonLabel="Submit text"
      />
        <h2>Alerts</h2>
        <ButtonGroup>
          <Button variant="contained" color="success" onClick={() => handleAlertClick("success")}>Success</Button>
          <Button variant="contained" color="warning" onClick={() => handleAlertClick("warning")}>Warning</Button>
          <Button variant="contained" color="error" onClick={() => handleAlertClick("error")}>Error</Button>          
        </ButtonGroup>
        <AlertOnPage localAlert={localAlert} />

        <h2>Language Switch <TranslateIcon /></h2>
        <ButtonGroup>
            <Button variant="contained" onClick={() => i18next.changeLanguage('sv').then(console.log("svenska"))}>{t("languages.sv")}</Button>
            <Button variant="contained" onClick={() => i18next.changeLanguage('en').then(console.log("engelska"))}>{t("languages.en")}</Button>
        </ButtonGroup>
        <p>{t("welcome")}</p>
    </div>
  )
}


export default FieldTypes
