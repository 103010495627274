import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import Form from "../forms/Form"
import '../../styles/forms/Form.css'
import '../../styles/forms/Components.css'
import { useTranslation } from 'react-i18next'



export default function AddPatientForm({ onSubmit, setWatchingData, disabledForm, patientSsn }) {
  const { control, handleSubmit, register, formState: { errors }, watch, setValue, resetField, getValues } = useForm({
    defaultValues: {
      ssn: patientSsn ?? "",
      firstName: "", 
      lastName: "",
      birthDate: null,
      sex: "",
    }
  })
  const { t } = useTranslation()
  
  const SEX_OPTIONS = [
    {"id": "F", "description": t("components.addPatient.AddPatientForm.inputFields.sex.items.F")},
    {"id": "M", "description": t("components.addPatient.AddPatientForm.inputFields.sex.items.M")},
    {"id": "O", "description": t("components.addPatient.AddPatientForm.inputFields.sex.items.O")}
  ]
  
    const birthDateDefault = watch("ssn")

    useEffect(() => {
      setWatchingData(birthDateDefault)
    }, [birthDateDefault])

    useEffect(() =>{
        if (/^(19|20)\d{6}[-]?\d{4}$/i.test(birthDateDefault) && birthDateReadOnly) {
        setValue('birthDate', new Date (`${birthDateDefault.substring(0,4)}-${birthDateDefault.substring(4,6)}-${birthDateDefault.substring(6,8)}`))
        }
    }, [birthDateDefault])

    const [birthDateReadOnly, setBirthDateReadOnly] = useState(true)
    const [sexExpected, setSexExpected] = useState(null)
    const [ssnWarningText, setSsnWarningText] = useState("")
    const [sexWarningText, setSexWarningText] = useState("")

    useEffect(() => {
      if(!getValues().sex){
        setValue('sex', sexExpected)
      }
    },[sexExpected])

    function ssnChecksum(ssn) {
      // Checks if ssn is valid by comparing the checksum value at the end of ssn with the result 
      // from a modified version of Luhn algorithm (212121-212 instead of 121212-121)
      
      // Reset state variables
      setSsnWarningText("")
      setSexWarningText("")
      setSexExpected(null)

      // Reset the sex field after ssn is changed because there might be a new default value
      resetField("sex")

      // Remove dash if it exists in the string
      var ssn = ssn.replace("-", "")
      // If the length is not equal to YYYYMMDDNNNN we don't check
      if (ssn.length !== 12) return
      // Remove the first two numbers, the algorithm only works for YYMMDDNNN but we keep the last
      // number for comparison at the end of the function
      else ssn = ssn.slice(2)
      
      // The algorithm
      let nDigits = ssn.length
      let nSum = 0
      let doubleNumber = true
      for (let i = nDigits - 2; i >= 0; i--)
      {
          let d = ssn[i].charCodeAt() - '0'.charCodeAt()

          if (doubleNumber === true)
              d = d * 2

          // We add two digits to handle cases that make two digits after doubling
          // Example: 16 -> 1 + 6 = 7
          nSum += parseInt(d / 10, 10)
          nSum += d % 10

          doubleNumber = !doubleNumber
      }

      // Set the default sex option
      if (ssn[nDigits-2] % 2 == 0) setSexExpected(SEX_OPTIONS[0].id)
      else setSexExpected(SEX_OPTIONS[1].id)

      // Validate the ssn by comparing to the checksum value
      if ((10 - (nSum % 10)) % 10 !== parseInt(ssn[nDigits - 1]))
        setSsnWarningText(t("components.addPatient.AddPatientForm.inputFields.ssn.warningText"))
      else setSsnWarningText("")
    }

    function sexWarning(value) {
      if (sexExpected !== null) {
        if (value !== sexExpected)
          setSexWarningText(t("components.addPatient.AddPatientForm.inputFields.sex.warningText"))
        else setSexWarningText("")
      }
      else setSexWarningText("")
    }

    const handleClickBirthDate = (()=> {setBirthDateReadOnly(prevState => !prevState)})

    const inputFields = [
        {
          fieldLabel: t("components.addPatient.AddPatientForm.inputFields.ssn.fieldLabel"),
          inputType: "Textfield",
          name: "ssn",
          placeholder: "YYYYMMDD-NNNN",
          type: "text",
          required: true,
          pattern: /^(19|20)\d{6}[-]?\d{4}$/i,
          patternMessage: t("components.addPatient.AddPatientForm.inputFields.ssn.patternMessage"),
          warning: ssnWarningText,
          warningCheck: ssnChecksum
        },
        {
          fieldLabel: t("components.addPatient.AddPatientForm.inputFields.firstName.fieldLabel"),
          inputType: "Textfield",
          name: "firstName",
          placeholder: t("components.addPatient.AddPatientForm.inputFields.firstName.placeholder"),
          type: "text",
          required: true,
        },
        {
          fieldLabel: t("components.addPatient.AddPatientForm.inputFields.lastName.fieldLabel"),
          inputType: "Textfield",
          name: "lastName",
          placeholder: t("components.addPatient.AddPatientForm.inputFields.lastName.placeholder"),
          type: "text",
          required: true,
        },
        {
          fieldLabel: t("components.addPatient.AddPatientForm.inputFields.birthDate.fieldLabel"),
          inputType: "Date",
          placeholder: "YYYY-MM-DD",
          name: "birthDate",
          required: true,
          readOnly: birthDateReadOnly,
          disabled: birthDateReadOnly,
          buttonLabel: t("components.addPatient.AddPatientForm.inputFields.birthDate.buttonLabel"),
          onClick: handleClickBirthDate,
          buttonId: "change-button-birth-date",
          datePickerDivClassName: "form-birthdate-div"
        },
        {
          fieldLabel: t("components.addPatient.AddPatientForm.inputFields.sex.fieldLabel"),
          inputType: "Radio",
          name: "sex",
          required: true,
          items: SEX_OPTIONS,
          warning: sexWarningText,
          warningCheck: sexWarning
        }
    ]

    return (
        <Box>
            <Form
              inputFields={inputFields}
              onSubmit={onSubmit}
              control={control}
              handleSubmit={handleSubmit}
              register={register}
              errors={errors}
              submitButtonLabel={t("components.addPatient.AddPatientForm.inputFields.form.submitButtonLabel")}
              disabledForm={disabledForm}
            />
        </Box>
    )
}