import * as React from 'react'
import {
  FormControl,
  TextField,
  Autocomplete,
  Chip
} from '@mui/material'
import { Controller } from 'react-hook-form'
import "../../styles/forms/Components.css"
import { useTranslation } from 'react-i18next'
import { displayWithCode } from '../general/Utils'

function AutocompleteComponent(props) {
    // Autocomplete component

    // The parameter props includes:
    // - name: string, name of input field
    // - label: string, input label text to be displayed in the input field
    // - items: array, a list of options of type object to choose from
    // - placeholder: string, placeholder text for input window
    // - multiple: boolean, should user be able to pick multiple items or not
    // - control, register

    const { t } = useTranslation()
    const name = props.name

    return (
        <FormControl>
            <Controller
                name={name}
                control={props.control}
                {...props.register(name,
                    {
                        required: {
                            value: props.required,
                            message: props.requiredMessage ?? t("components.fields.required")
                        },
                    }
                    )}
                ref={props.register}
                render={({
                        field: { onChange, ref },
                      }) => (
                    <Autocomplete
                        multiple={props.multiple ?? true}
                        options={props.items}
                        getOptionLabel={
                            (option) => {
                                if (props.displayCode) 
                                { 
                                    return displayWithCode(option.id, option.description, props.diagnosis)
                                } 
                                return option.description
                            }
                        }
                        defaultValue={props.multiple? props.defaultValue ?? [] : props.defaultValue ?? ""}
                        onChange={(_, value) => onChange(value)}
                        disabled={props.disabled}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                            <Chip
                                label={option.description}
                                {...getTagProps({ index })}
                            />
                            ))
                        }
                        style={{ width: 500 }}
                        renderInput={(params) => (
                        <TextField 
                            {...params} 
                            inputRef={ref}
                            label={props.label} 
                            placeholder={props.placeholder}
                            className='autocomplete'
                            helperText={props.noHelperText ? null : (props.errors[name] ? props.errors[name].message : " ") }
                            error={!!props.errors[name]}
                        />
                        )}
                    />
                )}
                />
        </FormControl>
    );
}

export default AutocompleteComponent