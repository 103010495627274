import './../../styles/Navigation.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

export default function LoggedIn() {
    const { user, isAuthenticated } = useAuth0()
    const { t } = useTranslation()
    return <div className="loggedin-line">
        {isAuthenticated && <h4>{t("components.navigation.LoggedIn")}: {user.name}</h4>}
    </div>
}