import React, { useState } from "react"
import { Typography, Popover } from '@mui/material'
import AutocompleteSingle from "../components/general/AutocompleteSingle"
import PatientInformationBox from "../components/general/PatientInformationBox"
import AddDiagnosisForm from "../components/addPatient/AddDiagnosisForm"
import SearchField from "../components/general/SearchField"
import AddLabResult from "./AddLabResult"
import { PrimaryButton } from "../components/general/Buttons"
import AddPatientPopUp from '../components/addPatient/AddPatientPopUp'
import { useTranslation } from 'react-i18next'

export default function MenuAddLabResult({accessToken, user}) {

    // TODO: Fix after adding diagnoses the page refreshes, emptying the ssn field

    const { t } = useTranslation()

    // Popup state variable to trigger popup if patient is not found and save ssn
    const [addPatientPopupOpen, setAddPatientPopupOpen] = useState(false)
    const [patientSsn, setPatientSsn] = useState('')
    // Popup state variable to trigger popup if diagnosis is not found
    const [addDiagnosisPopupOpen, setAddDiagnosisPopupOpen] = useState(false)
    const popupId = addDiagnosisPopupOpen ? 'simple-popover' : undefined
    // Patient state variable
    const [patient, setPatient] = useState({
        social_security_number: "",
        patient_first_name: "",
        patient_last_name: "",
        sex: ""
    })
    // Diagnosis dropdown is ready to be displayed on page
    const [diagnosisReady, setDiagnosisReady] = useState(false)
    // Patient has no diagnosis assigned
    const [noDiagnosis, setNoDiagnosis] = useState(false)
    // Diagnosis chosen from dropdown component
    const [diagnosis, setDiagnosis] = useState({id: 0, description: ""})
    // Diagnoses assigned to patient
    const [diagnosisDropdownItems, setDiagnosisDropdownItems] = useState({id: 0, description: ""})
    // Lab result form is ready to be displayed on page
    const [labResultFormReady, setLabResultFormReady] = useState(false)

    function handleClick() {
        setAddDiagnosisPopupOpen(true)
    }
    
    function handleClose() {
        setAddDiagnosisPopupOpen(false)
    }

    const onSubmitSsn = async (data) => {
        // Reset values, necessary when user inputs another ssn
        setDiagnosisReady(false)
        setDiagnosis({id: 0, description: ""})
        setDiagnosisDropdownItems({id: 0, description: ""})
        setLabResultFormReady(false)

        var promises = []
        const ssn = data["ssn"].replace('-','')

        return fetch(
            `http://localhost:8000/fast-api/patients/get_patient/${ssn}`,
            {
                method: "GET",
                headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    }
            }
        )
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                return response.json()
            }
            else {
                setPatientSsn(ssn)
                setAddPatientPopupOpen(true) // Show popup window that patient does not exist
                throw new Error(`Error. Response code: ${response.status}`)
            }
        })
        .then(responseData => {
            setPatient(responseData)
            for (const id of responseData.diagnosis_ids) {
                // Add promise, returned by calling the fetch function, to a list of promises
                promises.push(
                    fetch(
                        `http://localhost:8000/fast-api/algorithm/get_diagnosis_information/?diagnosis_id=${id}&language=${t("languages.queryParam")}`, 
                        {
                            method: "GET",
                            headers: {
                                    'Authorization': `Bearer ${accessToken}`,
                                    "Content-Type": "application/json"
                            }
                        }
                    )
                )
            }
            return Promise.all(promises)
        })
        .then(responses => {
            // Catch all responses and verify that the status of them all is ok
            return Promise.all(responses.map(response => {
                if (response.ok) {
                    console.log("response", response)
                    return response.json()
                }
                else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            }))
        })
        .then(diagnoses => {
            if (diagnoses.length > 0) {
                setDiagnosisDropdownItems(diagnoses)
                setDiagnosisReady(true)
                if (diagnoses.length === 1) {
                    // If patient only has one diagnosis it will be chosen in dropdown by default
                    setDiagnosis(diagnoses[0])
                    setLabResultFormReady(true)
                }
            }
            else setNoDiagnosis(true)
        })
    }

    const diagnosisDropdownHandleChange = (value) => {
        if (value !== null) {
            const diagnosisElected = diagnosisDropdownItems.find(obj => {
                return obj.id === value.id
            })
            console.log(value)
            setDiagnosis(diagnosisElected)
            setLabResultFormReady(true)
        }
        else {
            setDiagnosis({id: 0, description: ""})
            setLabResultFormReady(false)
        }
    }

    return (
        <div className="flexbox-page menu-add-lab-result">
            <h1>{t("pages.MenuAddLabResult.h1")}</h1>
            <p>{t("pages.MenuAddLabResult.p")}</p>
            <SearchField 
                accessToken={accessToken}
                divClassName={"form"}
                textFieldClassName={"menu-add-lab-result-patient-search-field"}
                iconClassName="navigation-search-field-icon"
                onSubmit={onSubmitSsn}
                popupState={addPatientPopupOpen}
                setPopupState={setAddPatientPopupOpen}
                noHelperText={false}
                disabled={patient.social_security_number !== ""}
            />
            {addPatientPopupOpen && <AddPatientPopUp 
                popupState={addPatientPopupOpen}
                setPopupState={setAddPatientPopupOpen}
                patientSsn={patientSsn}
            />}
            {noDiagnosis ?
            <div>
                <Typography>{t("pages.MenuAddLabResult.Typography")}</Typography>
                <PrimaryButton onClick={handleClick} label={t("pages.MenuAddLabResult.PrimaryButton.label")} />
            </div> :
            diagnosisReady && 
            <div>
                <PatientInformationBox
                    patientSsn={patient.social_security_number} 
                    patientSex={patient.sex} 
                    patientFirstName={patient.patient_first_name} 
                    patientLastName={patient.patient_last_name} 
                    patientNameHeader={false}
                />
                <AutocompleteSingle 
                    items={diagnosisDropdownItems}
                    onChange={diagnosisDropdownHandleChange}
                    placeholder={t("pages.MenuAddLabResult.AutocompleteSingle.placeholder")}
                    value={diagnosis.id !== 0 ? diagnosis : null}
                    displayCode={true}
                    diagnosis={true}
                />
                {labResultFormReady &&
                <div>
                    <h2>{t("pages.MenuAddLabResult.h2")}{diagnosis.description}</h2>
                    <AddLabResult 
                        accessToken={accessToken}
                        user={user}
                        hideInfo={true}
                        patientSsn={patient.social_security_number}
                        patientFirstName={patient.patient_first_name}
                        patientLastName={patient.patient_last_name}
                        patientSex={patient.sex}
                        diagnosisId={diagnosis.id}
                        diagnosisDescription={diagnosis.description}
                    />
                </div>
                }
            </div>}
            {
                patient.social_security_number !== "" && 
                <Popover
                    id={popupId}
                    open={addDiagnosisPopupOpen}
                    onClose={handleClose}
                    anchorReference={"none"}
                >
                    <div className="add-diagnoses-popup">
                        <AddDiagnosisForm 
                            accessToken={accessToken}
                            ssn={patient.social_security_number} 
                            navigateToPatient={false}
                        />
                    </div>
                </Popover>
            }
        </div>
    )

}