import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TranslateIcon from '@mui/icons-material/Translate'
import {availableLanguages} from '../../i18nextConf'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import i18next from 'i18next'

export default function LanguageSwitch() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLanguage = (language) => {
    setAnchorEl(null);
    if (i18next.language !== language) {
      i18next.changeLanguage(language)
    }
  }

  const { t } = useTranslation()

  return (
    <div className='language-switch'>
      <IconButton
        id="language-button"
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <TranslateIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
      >
        {availableLanguages.map(language => (
          language !== i18next.language ?
            <MenuItem onClick={() => handleLanguage(language)}>
                {t(`languages.${language}`)}
            </MenuItem>
            :
            <></>
        ))}
      </Menu>
    </div>
  )
}
