import React from 'react'
import { useForm } from 'react-hook-form'
import Form from '../forms/Form'
import { Box, Stack, Typography } from '@mui/material'
import { Link } from "react-router-dom"
import { PrimaryButton } from '../general/Buttons'
import { structionSsn, upperCaseName } from '../general/Utils'
import useAlert from '../alerts/useAlert'
import { useTranslation } from 'react-i18next'

function cleanUp(record) {
    var cleaned = []
    record.forEach(r => {
        cleaned.push({id: r["id"], description: r["date_time"]})
    })
    return cleaned
}

const CALCULATION_TIMEOUT = 15000

export default function StartCalculation({ accessToken, patientSsn, patientFirstName, patientLastName, patientSex, diagnosis, healthRecords, labResults, user, setIsCalculating, setGlobalAlert, setLocalAlert, setCalculationSucceeded }) {

    const { setAlert } = useAlert()
    const { t } = useTranslation()

    const { control, handleSubmit, register, formState: { errors } } = useForm()

    const healthRecordsExists = healthRecords.length > 0
    const labResultsExists = labResults.length > 0
    const cleanedHealthRecords = healthRecordsExists ? cleanUp(healthRecords) : []
    const cleanedLabResults = labResultsExists ? cleanUp(labResults) : []

    const formContent = [
        
        {
            fieldLabel: "Indicated drugs only",
            inputType: "Switch (true/false)",
            name: "test_switch",
            defaultValue: true
        },
        {
            fieldLabel: t("components.tables.StartCalculation.formContent.healthRecord.fieldLabel"),
            inputType: "Dropdown (select)",
            label: t("components.tables.StartCalculation.formContent.healthRecord.label"),
            name: "healthRecordId",
            required: true,
            canBeEmpty: false,
            items: cleanedHealthRecords,
            // defaultValue: (cleanedHealthRecords.length > 0 ? cleanedHealthRecords[0].id : "")
        },
        {
            fieldLabel: t("components.tables.StartCalculation.formContent.labResult.fieldLabel"),
            inputType: "Dropdown (select)",
            label: t("components.tables.StartCalculation.formContent.labResult.label"),
            name: "labResultId",
            required: true,
            canBeEmpty: false,
            items: cleanedLabResults,
            // defaultValue: (cleanedLabResults.length > 0 ? cleanedLabResults[0].id : "")
        }
    ]

    function calculationTimedOut() {
        setCalculationSucceeded(false)
        setIsCalculating(false)
        setLocalAlertTimeout()
    }
    
    function setLocalAlertTimeout() {
        console.log("calculation timed out")
        setLocalAlert(prevState => ({
            ...prevState,
            open: true,
            severity: "info",
            content: <div>
              <p>{t("alerts.calculationAlert.timeout")}</p>
            </div>,
            action: null
          }))
    }

    const onSubmitCalculation = (data) => {
        setIsCalculating(true)
        const timeout = setTimeout(calculationTimedOut, CALCULATION_TIMEOUT)
        console.log(data)
        fetch(`${process.env.REACT_APP_FAST_API_URI}/algorithm/start_calculation`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "patient_ssn": patientSsn,
                "diagnosis_id": diagnosis.id,
                "lab_result_id": data.labResultId,
                "health_record_id": data.healthRecordId
            })
        }
        )
        .then(response => {
            console.log("reponse:::")
            console.log(response)
            if (response.ok) {
                console.log("response", response)
                return response.json()
            } else {
                throw new Error(`Error. Response code: ${response.status}`)
            }
        })
        .then(responseData => {
            console.log(responseData)
            setCalculationSucceeded(responseData)
            clearTimeout(timeout)
            if (responseData) {
                // setLoading(false)
                setAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "success",
                    content: <div>
                      <p>{t("alerts.calculationAlert.success")}</p>
                    </div>,
                    action: null
                  }))
            } else {
                setLocalAlertTimeout()
            }
        },
        err => {
            console.log("err")
            console.log(err)
            setLocalAlert(prevState => ({
                ...prevState,
                open: true,
                severity: "warning",
                content: <div>
                  <p>{t("alerts.errorAlert.unexpectedError")}</p>
                </div>,
                action: null
              }))
              throw new Error(`Fetch error. ${err}`)
            }
            )
        .catch(error => {
            console.log("error:: ")
            console.log(error)
            setCalculationSucceeded(false)
            clearTimeout(timeout)
        })
        .finally(() => {
            setIsCalculating(false)
        })
    }

    return (
        <div>
        {
            (healthRecordsExists && labResultsExists) ?
            <div>
                <Typography variant="h2">{t("components.tables.StartCalculation.header")}</Typography>
                <Typography>{t("components.tables.StartCalculation.patientParagraph")}</Typography>
                <Typography>{upperCaseName(patientFirstName,patientLastName)}</Typography>
                <Typography>{structionSsn(patientSsn)}</Typography>
                <Typography>{t("components.tables.StartCalculation.diagnosisParagraph")}{diagnosis.description}</Typography>
                <Form
                    inputFields={formContent}
                    onSubmit={onSubmitCalculation}
                    control={control}
                    handleSubmit={handleSubmit}
                    register={register}
                    errors={errors}
                    submitButtonLabel={t("components.tables.StartCalculation.submitButtonLabel")}
                />
            </div> :
            <div>
                <Typography variant="h2">
                    {
                        (!healthRecordsExists && !labResultsExists) ? 
                        t("components.tables.StartCalculation.missingData.header.both") : 
                        (!healthRecordsExists ? t("components.tables.StartCalculation.missingData.header.onlyHealthRecord") : 
                        t("components.tables.StartCalculation.missingData.header.onlyLabResult"))
                    }
                </Typography>
                <Typography>
                    {
                        (!healthRecordsExists && !labResultsExists) ? 
                        t("components.tables.StartCalculation.missingData.paragraph.both") : 
                        (!healthRecordsExists ? t("components.tables.StartCalculation.missingData.paragraph.onlyHealthRecord") : 
                        t("components.tables.StartCalculation.missingData.paragraph.onlyLabResult"))
                    }
                </Typography>
                <Stack direction="row" spacing={2}>
                    {!healthRecordsExists &&
                        <Box>
                            <PrimaryButton
                            component={Link}
                            to="/add_health_record"
                            state={{patientSsn: patientSsn, patientFirstName: patientFirstName, patientLastName: patientLastName, patientSex: patientSex, diagnosisId: diagnosis.id, diagnosisDescription: diagnosis.description, user: user}}
                            label={t("components.tables.StartCalculation.addHRButtonLabel")}
                            />
                        </Box>
                    }
                    {!labResultsExists &&
                        <Box>
                            <PrimaryButton
                            component={Link}
                            to="/add_lab_result"
                            state={{patientSsn: patientSsn, patientFirstName: patientFirstName, patientLastName: patientLastName, patientSex: patientSex, diagnosisId: diagnosis.id, diagnosisDescription: diagnosis.description, user: user}}
                            label={t("components.tables.StartCalculation.addLRButtonLabel")}
                            />
                        </Box>
                    }
                </Stack>
            </div>
        }
        </div>
    )
}