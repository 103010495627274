import * as React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { Chip, Button, Typography, Paper, MobileStepper } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import MedicationDisplay from './MedicationDisplay'
import ReportTable from "./ReportTable"
import { useTranslation } from 'react-i18next'
import "../../styles/tables/ReportStepper.css"

function ReportStepper({ steps, type, params, diagnosisId, recommendationsExist }) {

    const { t } = useTranslation()
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = steps.length;

    // Used for rendering button text
    const TYPE_TRANSLATION = {
        "recommendation": t("components.tables.ReportStepper.typeTranslations.recommendation"),
        "health record": t("components.tables.ReportStepper.typeTranslations.healthrecord"),
        "lab result": t("components.tables.ReportStepper.typeTranslations.labresult")
    }
    
    function createReportTable(values, params, diagnosisId) {
        var paramName
        var row
        var value
        var tableRows = []
        try {
            for (const param of params) {
                paramName = param["name"].slice(0, -(diagnosisId.length + 1)).toLowerCase()
                // Check if value is an ID in a list of options
                if (param["options"] !== null) {
                    // Get the description of option/s
                    // Check if one id value or a list of ids
                    if (typeof values[paramName] === "string") value = getDescription(values[paramName], param["options"])
                    else if (Array.isArray(values[paramName])) {
                        value = values[paramName].map((id) => {return <p>{getDescription(id, param["options"], param["display_code"])}</p>})
                    }
                    else throw new Error("Error. Invalid value for option")
                }
                else if(typeof values[paramName] === "boolean"){
                    {
                        values[paramName] ? 
                        value=t("components.tables.ReportStepper.funcCreateReportTable.booleanOption.True") : 
                        value=t("components.tables.ReportStepper.funcCreateReportTable.booleanOption.False")
                    }
                }
                else {
                    value = values[paramName].toLocaleString(t("localeStringFormatter"))
                }
                row = {
                    "name": param["table_text"],
                    "information": param["extra_information"],
                    "value": value,
                    "unit": param["unit"]
                }
                tableRows.push(row)
            }
            return <ReportTable rows={tableRows} />
        } catch (error) {
            return null
        }
    }

    function getDescription(id, options, displayCode) {
        console.log(displayCode)
        for (const option of options) {
            if (option["id"] === id) {
                if (displayCode) return option["description"] +" ["+option["id"]+"]"
                return option["description"]
            }
        }
        throw new Error("Error. Option not found in list of options")
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    };

    return (
        <Box className="report-steper" sx={{ maxWidth: 700, flexGrow: 1 }}>
            <Paper
                elevation={0}
                variant="outlined"
                className="report-stepper-header"
            >
                <Typography variant="h6" gutterBottom>{steps[activeStep]['date_time']}</Typography>
                {steps[activeStep]['used'] && <Chip label={t("components.tables.ReportStepper.Paper.Chip.usedInCalc")} color="success"/>}
                {type !=  'recommendation' && (recommendationsExist && (activeStep == 0 && !steps[activeStep]['used'])) &&  <Chip label={t("components.tables.ReportStepper.Paper.Chip.notUsedInCalc")} color="warning"/>}
            </Paper>
            <Box className="report-steper-window" sx={{ maxWidth: 700, width: '100%', p: 2 }}>
                {
                    type == 'recommendation'? 
                    <MedicationDisplay 
                        key={activeStep}
                        recommendation={steps[activeStep]}
                        first={activeStep === 0}
                    /> : 
                    createReportTable(steps[activeStep], params, diagnosisId)
                }
            </Box>
            <MobileStepper
                className="report-stepper-stepper"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                >
                    {t("components.tables.ReportStepper.stepButtons.older", {type: TYPE_TRANSLATION[type]})}
                    {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                    ) : (
                    <KeyboardArrowRight />
                    )}
                </Button>
                }
                backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                    ) : (
                    <KeyboardArrowLeft />
                    )}
                    {t("components.tables.ReportStepper.stepButtons.newer", {type: TYPE_TRANSLATION[type]})}
                </Button>
                }
            />
        </Box>
    );
}

export default ReportStepper