import React, { useState, useEffect, useMemo } from "react"
import { useForm } from 'react-hook-form'
import Form from './Form'
import { useTranslation } from 'react-i18next'

function ReportForm({ accessToken, url, type, onSubmit, birthDate, previousRecord, diagnosisId, submitButtonLabel }) {
    // ReportForm component is used when adding a health record or lab result

    // The Parameter props includes:
    // - url: string, url to get parameters for either a health record or a lab result
    // - type: string, either "heathRecord" or "labResult"
    // - onSubmit: function, function that handles submission of form
    // - birthDate: Date of birth of patient, used by fields to check if time is before date of birth

    const { t } = useTranslation()

    const [inputFields, setInputFields] = useState([])
    const [defaultValuesUpdated, setdefaultValuesUpdated] = useState(false)
    const [defaultValuesFetched, setdefaultValuesFetched] = useState({})
    const { control, handleSubmit, register, formState: { errors }, watch, setValue, reset } = useForm({
        defaultValues: useMemo(() => {
                    return defaultValuesFetched
                }, [defaultValuesFetched])
    })


    const birthDateDefault = watch("ssn")
    

    React.useEffect(() =>{
        if (/^(19|20)\d{6}[-]?\d{4}$/i.test(birthDateDefault)){
            setValue('birthDate', `${birthDateDefault.substring(0,4)}-${birthDateDefault.substring(4,6)}-${birthDateDefault.substring(6,8)}`)
        }
    })

    function fetchData() {
            fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
                .then(response => {
                    if (response.ok) {
                        console.log("response", response)
                        return response.json()
                    } else {
                        throw new Error(`Error. Response code: ${response.status}`)
                    }
                })
                .then(responseData => {
                    if (type === "labResult") {
                        responseData.unshift({
                            name: "lab_date",
                            extra_information: t("components.forms.ReportForm.lab_date.extra_information"),
                            form_text: t("components.forms.ReportForm.lab_date.form_text"),
                            input_type: "Date time",
                            readOnly: false,
                            birthDate: birthDate
                        })
                    }
                    setInputFields(responseData.map(inputField => {
                        var param = null
                        if(previousRecord != null){
                            param = inputField.name.replace('_'+diagnosisId , '')
                            if(param=="related_diseases"){
                                var defaultRelatedDisease = []
                                for(var item in previousRecord[param]){
                                    defaultRelatedDisease.push(inputField.options.find(keyValuePair => keyValuePair.id === previousRecord[param][item]))
                                }
                            }
                            setdefaultValuesFetched (prevValue => ({...prevValue, [inputField.name]: defaultRelatedDisease ??  previousRecord[param]}))
                        }
                        return {
                            key: inputField.name,
                            fieldLabel: inputField.form_text,
                            information: inputField.extra_information,
                            inputType: inputField.input_type,
                            name: inputField.name,
                            max: inputField.max_value,
                            maxMessage: `${t("components.forms.ReportForm.inputField.maxMessage")} ${inputField.max_value}`,
                            min: inputField.min_value,
                            minMessage: `${t("components.forms.ReportForm.inputField.minMessage")} ${inputField.min_value}`,
                            items: inputField.options,
                            optionsTable: inputField.optionsTable,
                            placeholder: inputField.placeholder_double || inputField.placeholder_integer || inputField.placeholder_text,
                            pattern: inputField.regex,
                            endAdornment: inputField.unit,
                            type: inputField.input_type !== "Textfield" && "number",
                            readOnly: inputField.readOnly,
                            required: !inputField.can_be_null,
                            birthDate: birthDate,
                            defaultValue: defaultRelatedDisease ?? null,
                            displayCode: inputField.display_code,
                        }
                    }))
                    setdefaultValuesUpdated(true)
                })
    }

   

    useEffect(() => {
        fetchData()
    }, [url])

    useEffect(() => {
        if(defaultValuesUpdated){
            reset(defaultValuesFetched)
        }
    }, [defaultValuesUpdated, reset])


    return (
        <Form
            inputFields={inputFields}
            onSubmit={onSubmit}
            control={control}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            submitButtonLabel={submitButtonLabel}
        />
    )
}

export default ReportForm