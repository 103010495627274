import * as React from 'react'
import {
  MenuItem,
  Select
} from '@mui/material'

export default function Dropdown({ id, value, handleChange, items }) {
    // Dropdown component where the user can only select one value
  
    // The Parameter props includes:
    // - id: string, id for select
    // - value: useState value, changed by setValue through "handleChange"
    // - handelChange: function, onChange function for the dropdown
    // - items: array, a list of options to choose from

    return <div>
    <Select
        id={id}
        value={value}
        displayEmpty
        onChange={handleChange}
        sx={{minWidth: 250 }}
    >
        {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
        ))}
    </Select>
    </div>
}