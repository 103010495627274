import * as React from 'react'
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Box,
  Chip
} from '@mui/material'
import { Controller } from 'react-hook-form'
import "../../styles/forms/Components.css"
import { useTranslation } from 'react-i18next'

function SelectOne(props) {
  // Dropdown component where the user can only select one value

  // The Parameter props includes:
  // - name: string, name of input field
  // - label: string, input label text to be displayed in the input field
  // - required: boolean, represents if the input field is required to submit form
  // - (Optional) requiredMessage: string, message displayed when value is missing from input field
  // - canBeEmpty: boolean, purpose is to be able to reset the value chosen to an empty string
  // - items: array, a list of options to choose from
  // - control, register, errors
  // - (Optional) defaultValue: id of the default value of the dropdown
  const { t } = useTranslation()
  const name=props.name

  return (
    <FormControl className="select-one">
      <InputLabel error={!!props.errors[name]}>{props.label}</InputLabel>
      <Controller
        name={name}
        control={props.control}
        defaultValue={props.defaultValue ? props.defaultValue : ""} // Default value set to empty string to avoid a warning
        {...props.register(name, 
          {
              required: {
                  value: props.required,
                  message: props.requiredMessage ?? t("components.fields.required")
              }
          }
        )}
        ref={null}
        render={({ field }) => (
          <Select {...field}
            error={!!props.errors[name]}
            autoWidth
            label={props.label}
            sx={{minWidth: 95 }}
            disabled={props.disabled}
          >
            {props.canBeEmpty && <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
            }
            {props.items.map((item) => (
              <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText error={true}>{props.errors[name] ? props.errors[name].message : ""}</FormHelperText>
    </FormControl>
  )
}


function SelectMultiple(props) {
  // Dropdown component where the user can select multiple values

  // The Parameter props includes:
  // - name: string, name of input field
  // - label: string, input label text to be displayed in the input field
  // - required: boolean, represents if the input field is required to submit form
  // - (Optional) requiredMessage: string, message displayed when value is missing from input field
  // - items: array, a list of options to choose from
  // - control, register, errors
  const { t } = useTranslation()
  const name=props.name
  return (
    <FormControl className="select-multi">
      <InputLabel error={!!props.errors[name]}>{props.label}</InputLabel>
      <Controller
        name={name}
        control={props.control}
        defaultValue={[]} // Default value set to empty string to avoid a warning
        {...props.register(name, 
          {
              required: {
                  value: props.required,
                  message: props.requiredMessage ?? t("components.fields.required")
              }
          }
        )}
        ref={null}
        render={({ field }) => (
          <Select {...field} 
            error={!!props.errors[name]}
            multiple
            autoWidth
            label={props.label}
            disabled={props.disabled}
            sx={{minWidth: 165 }} // Might need max width for styling
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {props.items.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText error={true}>{props.errors[name] ? props.errors[name].message : ""}</FormHelperText>
    </FormControl>
  )
}


export {SelectOne, SelectMultiple}