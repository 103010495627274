import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import Dropdown from '../components/general/Dropdown'
import PatientTabs from '../components/tables/PatientTabs'
import { Box, Typography, Popover, Button } from '@mui/material'
import AddDiagnosisForm from "../components/addPatient/AddDiagnosisForm"
import PatientInformationBox from "../components/general/PatientInformationBox"
import { PrimaryButton, SecondaryButton, SlimButton } from "../components/general/Buttons";
import { upperCaseName, sortDataList, displayWithCode } from '../components/general/Utils'
import useAlert from '../components/alerts/useAlert'
import i18next from "i18next"
import { useTranslation } from 'react-i18next'


function Patient({accessToken, user}) {

    let location = useLocation()
    const { t } = useTranslation()

    const patientSsn = location.state.patientSsn

    const { setAlert } = useAlert()

    const [noDiagnosis, setNoDiagnosis] = useState(false)
    const [goToPatientReady, setGoToPatientReady] = useState(false)

    
    const [patientSex, setPatientSex] = useState("")
    const [patientFirstName, setPatientFirstName] = useState("")
    const [patientLastName, setPatientLastName] = useState("")
    const [diagnosis, setDiagnosis] = useState({id: 0, description: ""})
    const [diagnosisDropdownItems, setDiagnosisDropdownItems ] = useState([{}])
    const addDiagnosisButton = { id: "addDiagnosisButton", description: <SlimButton onClick={handleClick} label={t("pages.Patient.addDiagnosisButton.label")} />}

    const [popupOpen, setPopupOpen] = useState(false)

    const [userSubscribedToPatient, setUserSubscribedToPatient] = useState(null)

    function fetchSubscribedStatus(){
        return fetch(`${process.env.REACT_APP_FAST_API_URI}/users/subscription_exists?patient_ssn=${patientSsn}&user_id=${user.id}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                },
        })
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                return response.json()
            } else {
                throw new Error(`Error. Response code: ${response.status}`)
            }
        })
        .then(responseData => {
            setUserSubscribedToPatient(responseData)
        })
        .catch(error => {
        console.log("error:: ")
        console.log(error)
        })  
    }

    useEffect(()=> {
        fetchSubscribedStatus()
    }, [])

    function handleClickSubscriptionButton(){
        userSubscribedToPatient? deleteSubscription() : addSubscription()
        setUserSubscribedToPatient(prevState => !prevState)
    }

    function deleteSubscription(){
        return fetch(
            `${process.env.REACT_APP_FAST_API_URI}/users/delete_subscription`,
            {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "patient_ssn": patientSsn,
                "user_id": user.id
            })
            }
        )
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                setAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "info",
                    content: <div>
                      <p>{upperCaseName(patientFirstName,patientLastName)} {t("alerts.addToYourPatientsAlert.removed")}</p>
                    </div>,
                    action: <Button color="inherit" size="small" onClick={undoDeleteSubscription}>
                    {t("alerts.addToYourPatientsAlert.regret_button")}
                  </Button>
                  }))
                return response.json()
            } else {
                throw new Error(`Error. Response code: ${response.status}`)
            }
        })
        .catch(error => {
            console.log("error:: ")
            console.log(error)
        })
    }

    function addSubscription(){
        return fetch(
            `${process.env.REACT_APP_FAST_API_URI}/users/add_subscription`,
            {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "patient_ssn": patientSsn,
                "user_id": user.id
            })
            }
        )
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                setAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "success",
                    content: <div>
                      <p>{upperCaseName(patientFirstName,patientLastName)} {t("alerts.addToYourPatientsAlert.success")}</p>
                    </div>,
                    action: null
                  }))
                return response.json()
            } else {
                throw new Error(`Error. Response code: ${response.status}`)
            }
        })
        .catch(error => {
            console.log("error:: ")
            console.log(error)
        })
    }

    function handleClick() {
        setPopupOpen(true)
    }

    const handleClose = () => {
        setPopupOpen(false)
    }

    const handleGoToPatient = () => {
        setPopupOpen(false)
        fetchDiagnoses()
    }

    useEffect (() => {
        if (diagnosisDropdownItems.length > 0) {
            setNoDiagnosis(false)
        } else {
            setNoDiagnosis(true)
        }
    }, [diagnosisDropdownItems])

    function undoDeleteSubscription() {
        addSubscription()
        fetchSubscribedStatus()
        diagnosisDropdownHandleChange()
    }

    const popupId = popupOpen ? 'simple-popover' : undefined;

    function fetchDiagnoses() {
        var promises = []
        return fetch(`${process.env.REACT_APP_FAST_API_URI}/patients/get_patient/${patientSsn}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                },
        })
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                return response.json()
            } else {
                throw new Error(`Error. Response code: ${response.status}`)
            }
        })
        .then(responseData => {
            setPatientSex(responseData['sex'])
            setPatientFirstName(responseData['patient_first_name'])
            setPatientLastName(responseData['patient_last_name'])
            const ids = responseData.diagnosis_ids
            ids.forEach(id => {
                // Add to a list of promises returned by calling the fetch function
                promises.push(fetch(`${process.env.REACT_APP_FAST_API_URI}/algorithm/get_diagnosis_information/?diagnosis_id=${id}&language=${t("languages.queryParam")}`, 
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        },
                }))
            })
            return Promise.all(promises)
        })
        .then(responses => {
            // Catch responses and verify that the status of them all is ok
            return Promise.all(responses.map(response => {
                if(response.ok) {
                    console.log("response", response)
                    return response.json()
                }
                else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            }))
        })
        .then(diagnoses => {
            if (diagnoses.length > 0) {
                diagnoses = sortDataList(diagnoses, "description")
                diagnoses = diagnoses.map((item) => {return {"id" : item.id, "description" : displayWithCode(item.id, item.description, true)}})
                setDiagnosisDropdownItems(diagnoses.concat(addDiagnosisButton))
            } else {
                setNoDiagnosis(true)
            }
            if (location.state.diagnosisId) {
            // if (location.state.diagnosisId && location.state.diagnosisDescription) {
                setDiagnosis({id: location.state.diagnosisId, description: location.state.diagnosisDescription})
            } else {
                setDiagnosis(diagnoses[0])
            }
        })
    }


    useEffect(() => {
        fetchDiagnoses()
    }, [patientSsn])

    const diagnosisDropdownHandleChange = (event) => {
        const diagnosisElected = diagnosisDropdownItems.find(obj => {
            return obj.id == event.target.value
        })
        console.log(event.target.value)
        if (diagnosisElected.id !== addDiagnosisButton.id) {
            setDiagnosis(diagnosisElected)
        }
    }


    return (
        <div className="flexbox-page patient">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <PatientInformationBox patientSsn={patientSsn} patientSex={patientSex} patientFirstName={patientFirstName} patientLastName={patientLastName} patientNameHeader={true}/>
                {userSubscribedToPatient? (
                    <SecondaryButton variant="text" label={t("pages.Patient.removeFromMyPatientsButton.label")} red={true} onClick={handleClickSubscriptionButton}/>
                ) : (
                    <SecondaryButton variant="text" label={t("pages.Patient.addToMyPatientsButton.label")} onClick={handleClickSubscriptionButton}/>
                )}
            </Box>
            {noDiagnosis? 
            <div>
                <Typography>{t("pages.Patient.Typography")}</Typography>
                <PrimaryButton onClick={handleClick} label={t("pages.Patient.addDiagnosisButton.label")} />
            </div> :
            <div>
                <Dropdown
                    id="diagnosis-picker"
                    value={diagnosis.id}
                    handleChange={diagnosisDropdownHandleChange}
                    items={diagnosisDropdownItems}
                />
                <PatientTabs
                    accessToken={accessToken}
                    diagnosis={diagnosis}
                    patientSsn={patientSsn}
                    patientFirstName={patientFirstName}
                    patientLastName={patientLastName}
                    patientSex={patientSex}
                    user={user}
                />
            </div>
            }
            <Popover
                id={popupId}
                open={popupOpen}
                onClose={handleClose}
                anchorReference={"none"}
            >
                <div className="add-diagnoses-popup">
                    <AddDiagnosisForm
                        accessToken={accessToken}
                        ssn={patientSsn} 
                        navigateToPatient={false}
                        setGoToPatientReady={setGoToPatientReady}
                    />
                    {goToPatientReady ?
                        <SecondaryButton onClick={handleGoToPatient} label={t("pages.Patient.goToPatientButton.label")}/>
                    : <></>}
                </div>
            </Popover>
        </div>
    )
}


export default Patient