import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ReportForm from "../components/forms/ReportForm"
import PatientInformationBox from "../components/general/PatientInformationBox"
import useAlert from '../components/alerts/useAlert'
import AlertOnPage from "../components/alerts/AlertOnPage"
import i18next from "i18next"
import { useTranslation } from 'react-i18next'

export default function AddLabResult({accessToken, user, ...props}) {
    let navigate = useNavigate()
    let location = useLocation()
    const { t } = useTranslation()
    var patientSsn, patientSex, patientFirstName, patientLastName, diagnosisId, diagnosisDescription

    if (Object.keys(props).length === 0) {
        patientSsn = location.state.patientSsn
        patientFirstName = location.state.patientFirstName
        patientLastName = location.state.patientLastName
        patientSex = location.state.patientSex
        diagnosisId = location.state.diagnosisId
        diagnosisDescription = location.state.diagnosisDescription
    }
    else {
        patientSsn = props.patientSsn
        patientFirstName = props.patientFirstName
        patientLastName = props.patientLastName
        patientSex = props.patientSex
        diagnosisId = props.diagnosisId
        diagnosisDescription = props.diagnosisDescription
    }

    const { setAlert } = useAlert()
    const [localAlert, setLocalAlert] = useState({})

    const onSubmit = (data) => {
        console.log("data", data)
        const { lab_date, ...dataModified } = data
        const year = lab_date.getFullYear()
        const month = lab_date.getMonth() + 1 // getMonth starts from 0
        const date = lab_date.getDate()
        const hours = lab_date.getHours()
        const minutes = lab_date.getMinutes()
        const seconds = lab_date.getSeconds()
        const milliseconds = lab_date.getMilliseconds()
        for(var key in dataModified) {
            if (dataModified[key] instanceof Array) {
                dataModified[key] = Object.entries(dataModified[key]).map( ([_, value]) => value.id)
            }
        }
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "patient_ssn": patientSsn,
                "diagnosis_id": diagnosisId,
                "lab_date": `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${milliseconds}`,
                "params": dataModified
            })
        }
        fetch(
            `${process.env.REACT_APP_FAST_API_URI}/lab/add_lab_result`,
            requestOptions
        )
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                setAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "success",
                    content: <div>
                      <p>{t("alerts.addToSystemAlert.LR_success")}</p>
                    </div>,
                    action: null
                }))
                navigate(
                    "/patient",
                    {
                        state: {
                            patientSsn: patientSsn,
                            diagnosisId: diagnosisId,
                            diagnosisDescription: diagnosisDescription
                        }
                    }
                )
                return response.json()
            } else {
                setLocalAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "warning",
                    content: <div>
                      <p>{t("alerts.errorAlert.unknownError")}</p>
                    </div>,
                    action: null
                  }))
                throw new Error(`Error. Response code: ${response.status}`)
            }
        },
        err => {
            console.log("err")
            console.log(err)
            setLocalAlert(prevState => ({
                ...prevState,
                open: true,
                severity: "warning",
                content: <div>
                  <p>{t("alerts.errorAlert.unexpectedError")}</p>
                </div>,
                action: null
              }))
              throw new Error(`Fetch error. ${err}`)
            }
            )
        .catch(error => {
            console.log("error:: ")
            console.log(error)
          })
        
    }

    return (
        <div>
            {props.hideInfo !== true && <div>
                <h1>{t("pages.AddLabResult.h1")} {diagnosisDescription}</h1>
                <PatientInformationBox 
                    patientSsn={patientSsn} 
                    patientSex={patientSex} 
                    patientFirstName={patientFirstName} 
                    patientLastName={patientLastName} 
                    patientNameHeader={false}
                />
            </div>}
            <ReportForm
                accessToken={accessToken}
                url={`${process.env.REACT_APP_FAST_API_URI}/lab/get_lab_result_parameters/${diagnosisId}?language=${t("languages.queryParam")}`}
                type="labResult"
                onSubmit={onSubmit}
                submitButtonLabel={t("pages.AddLabResult.ReportForm.submitButtonLabel")}
            />
            <AlertOnPage localAlert={localAlert} />
        </div>
    )
}
