import { Alert, AlertTitle, Collapse } from '@mui/material'

export default function AlertOnPage({localAlert}) {

  return (
      <Collapse in={localAlert.open}>
        <Alert severity={localAlert.severity} variant="standard" sx={{ width: "auto" }}>
          {localAlert.title? <AlertTitle>{localAlert.title}</AlertTitle> : <></>}
          {localAlert.content}
        </Alert>
      </Collapse>
    )
}