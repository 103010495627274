import { Route, Routes, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from "react"
import './App.css'
import YourPatients from './pages/YourPatients'
import Navigation from './components/navigation/Navigation'
import LoggedIn from './components/navigation/LoggedIn'
import Patient from './pages/Patient'
import FieldTypes from './pages/FieldTypes'
import AddHealthRecord from './pages/AddHealthRecord'
import AddLabResult from './pages/AddLabResult'
import MenuAddLabResult from './pages/MenuAddLabResult'
import AddPatient from './pages/AddPatient'
import Profile from './pages/Profile'
import Footer from './components/navigation/Footer'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useAuth0 } from "@auth0/auth0-react"
import { AlertProvider } from './components/alerts/AlertContext.js'
import AlertPopup from './components/alerts/AlertPopup'
import { useTranslation } from 'react-i18next'

const THEME = createTheme({
  typography: {
   "fontFamily": `"Assistant", sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});

function App() {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const { t } = useTranslation()

  const location = useLocation()

  const navItems = [
    t("navbar.myPatients").toUpperCase(),
    t("navbar.addLabResult").toUpperCase(),
    t("navbar.addPatient").toUpperCase(),
    t("navbar.myProfile").toUpperCase()
  ]
  const hrefs = ["/", "/menu_add_lab_result", "/add_patient", "/profile"]

  const { getAccessTokenSilently, user } = useAuth0()
 
  //Logged in user:
  const [userObject, setUserObject] = useState()

  const [token, setToken] = useState()

  function getToken() {
    return getAccessTokenSilently({
      audience: `https://userapi.com`,
      ignoreCache: true,
      scope: "offline_access"
    })
    .then(receivedToken => {
        return receivedToken
    })
  }

  function getUser() {
    return getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    })
    .then(response => {
      const accessToken = response
      return fetch(`https://${domain}/api/v2/users/${user.sub}`, {
          headers: {
              Authorization: `Bearer ${accessToken}`,
          },
      })
    })
    .then(response => {
      return response.json()
    })
    .then(response => {
      const tUser = {
        user_name: response.name,
        id: response.user_metadata.uuid,
        picture: response.picture,
        email: response.email,
        role: response.user_metadata.role[0],
        last_login: response.last_login,
        email_verified: response.email_verified,
        created_at: response.created_at
      }
      return tUser
    })
    .catch(error => {
        console.log("error:: ")
        console.log(error)
    })
  }

  useEffect(async () => {
    const fetchedUser = await getUser()
    setUserObject(fetchedUser)
  }, [])

  useEffect(async () => {
    const fetchedToken = await getToken()
    setToken(fetchedToken)
  }, [])
 
  return (
    <ThemeProvider theme={THEME}>
      <div className="app">
      <AlertProvider>
          <Navigation navItems={navItems} hrefs={hrefs} accessToken={token} />
          {token && userObject ?
           <div className='page'>
            <LoggedIn />
            <Routes>
              <Route path="/" element={<YourPatients user={userObject} accessToken={token} key={location.key} />} />
              <Route path="/patient" element={<Patient user={userObject} accessToken={token} key={location.key} />} />
              <Route path="/add_health_record" element={<AddHealthRecord accessToken={token} key={location.key} />} />
              <Route path="/add_lab_result" element={<AddLabResult user={userObject} accessToken={token} key={location.key} />} />
              <Route path="/add_patient" element={<AddPatient user={userObject} accessToken={token} key={location.key} />} />
              <Route path="/profile" element={<Profile user={userObject} key={location.key} />} />
              <Route path="/examples" element={<FieldTypes />} />
              <Route path="/menu_add_lab_result" element={<MenuAddLabResult user={userObject} accessToken={token} key={location.key} />} />
            </Routes>
            <AlertPopup />
            </div> : <p>Loading... </p>
            }
          <Footer />
      </AlertProvider>
    </div>
    </ThemeProvider>
  );
}

export default App;
