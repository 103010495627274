import React, { useState } from 'react'
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import Popover from '@mui/material/Popover'
import SearchField from "../general/SearchField"
import AddPatientPopUp from '../addPatient/AddPatientPopUp'

export default function NavigationSearchField({ accessToken, divClassName }) {
    // Navigation search field to open search popup and/or patient does not exist popup

    // The Parameter props includes:
    // - accessToken
    // - divClassName: string, classname for wrapping div

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClickSearch = (event) => {
        setAnchorEl(event.currentTarget)
    }

    function handleCloseSearch() {
        setAnchorEl(null)
    }
    const [popupState, setPopupState] = useState(false) // Patient not exist popUp
    const [patientSsn, setPatientSsn] = useState('')

    return (
        <div className={divClassName ? divClassName : null}>
            <IconButton 
                id="navigation-search-button"
                aria-controls={open ? 'search-field' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSearch}
            >
                <SearchIcon className="navigation-search-button-icon"/>
            </IconButton>
            <Popover
                id="search-field"
                className="navigation-search-popup"
                anchorOrigin={{
                    vertical: 45,
                    horizontal: 40,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseSearch}    
                >
                <SearchField
                    accessToken={accessToken}
                    divClassName={"navigation-search-popup-search-field"}
                    textFieldClassName={"navigation-search-field"}
                    iconClassName={"navigation-search-field-icon"}
                    popupState={popupState}
                    setPopupState={setPopupState}
                    handleCloseSearch={handleCloseSearch}
                    setPatientSsn={setPatientSsn}
                />
            </Popover>
            {popupState && <AddPatientPopUp 
                popupState={popupState}
                setPopupState={setPopupState}
                patientSsn={patientSsn}
            />}
        </div>
    )
}