import React, { useState } from "react"
import { Link } from "react-router-dom"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Popover } from '@mui/material'
import { SecondaryButton, ArrowButton, AddButton } from "../../components/general/Buttons"
import '../../styles/tables/PatientsTable.css'
import { structionSsn, upperCaseName } from '../general/Utils'
import AddDiagnosisForm from "../../components/addPatient/AddDiagnosisForm"
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'


export default function PatientsTable({accessToken, user, rows, setPatientTableUpdates}) {
  const { t } = useTranslation()
  
  const [popupOpen, setPopupOpen] = useState(false)
  const popupId = popupOpen ? 'simple-popover' : undefined;
  const [currentSsn, setCurrentSsn] = useState("")  
  const [goToPatientReady, setGoToPatientReady] = useState(false)

  function handleClick(currentSsn) {
    setCurrentSsn(currentSsn)
    setPopupOpen(true)
  }

  const handleClose = () => {
      setCurrentSsn("")
      setPopupOpen(false)
  }

  const handleGoToPatient = () => {
    setPopupOpen(false)
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const hoverPopUpOpen = Boolean(anchorEl);
  const hoverPopUpId = hoverPopUpOpen ? 'mouse-over-popover' : undefined

  return (
    <div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("components.tables.PatientsTable.table.cellHeader.ssn")}</TableCell>
            <TableCell>{t("components.tables.PatientsTable.table.cellHeader.name")}</TableCell>
            <TableCell align="right">{t("components.tables.PatientsTable.table.cellHeader.diagnoses")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.patientSsn}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link className="patient-table-link" to="/patient" state={{patientSsn: row.patientSsn}}>
                {structionSsn(row.patientSsn)}
                </Link>
              </TableCell>
              <TableCell component="th" scope="row">
                <Link  className="patient-table-link" to="/patient" state={{patientSsn: row.patientSsn}}>
                {upperCaseName(row.patientFirstName,row.patientLastName)}
                </Link>
              </TableCell>
              <TableCell sx={{paddingBottom:"4px"}}>
                <div className="your-patients-diagnoses-column" align="right">
                
                  {row.diagnoses.map((diagnosis) => {
                    return (
                      <ArrowButton label={diagnosis.description} component={Link} to="/patient" state={{patientSsn: row.patientSsn, diagnosisId: diagnosis.id, diagnosisDescription: diagnosis.description}} />
                  )})
                  }
                  <AddButton onClick={() => handleClick(row.patientSsn)} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}/>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Popover
      id={popupId}
      open={popupOpen}
      onClose={handleClose}
      anchorReference={"none"}          
    >
      <div className="add-diagnoses-popup">
          <AddDiagnosisForm accessToken={accessToken} ssn={currentSsn} navigateToPatient={false} setGoToPatientReady={setGoToPatientReady} setPatientTableUpdates={setPatientTableUpdates}/>
          {goToPatientReady ?
              <SecondaryButton onClick={handleGoToPatient} label={t("components.tables.PatientsTable.Popover.buttonLabel")} component={Link} to="/patient" state={{patientSsn: currentSsn, user: user}}/>
          : <></>}
        </div>
    </Popover>
    <Popover
      id={hoverPopUpId}
      sx={{
        pointerEvents: 'none',
      }}
      open={hoverPopUpOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography sx={{ p: 1 }}>{t("components.tables.PatientsTable.HoverPopover")}</Typography>
    </Popover>
    </div>
  );
}