import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import "../../styles/tables/ReportTable.css"
import { InformationIcon } from '../general/InformationIcon'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function ReportTable({ rows }) {
  const { t } = useTranslation()
  return (
    <TableContainer className="report-table-container" component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><strong>{t("components.tables.ReportTable.cellHeader.param")}</strong></TableCell>
            <TableCell align="right"><strong>{t("components.tables.ReportTable.cellHeader.value")}</strong></TableCell>
            <TableCell align="right"><strong>{t("components.tables.ReportTable.cellHeader.unit")}</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Stack direction="row" style={{display: 'flex', alignItems: 'center'}}>
                  {row.name} 
                  {row.information && <InformationIcon text={row.information} /> }
                </Stack>
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
