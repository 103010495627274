import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import AddPatientForm from "../components/addPatient/AddPatientForm"
import AddDiagnosisForm from "../components/addPatient/AddDiagnosisForm"
import { Link } from "react-router-dom"
import { SecondaryButton } from "../components/general/Buttons"
import useAlert from '../components/alerts/useAlert'
import AlertOnPage from "../components/alerts/AlertOnPage"
import { useTranslation } from 'react-i18next'
  
function AddPatient({accessToken, user}) {
  let location = useLocation()
  const { t } = useTranslation()
  const patientSsn = location.state === null ? null : location.state.patientSsn

  const [step, setStep] = useState(1)

  const [ssn, setSsn ] = useState(patientSsn)
  const [goToPatientReady, setGoToPatientReady] = useState(false)

  const { setAlert } = useAlert()
  const [ localAlert, setLocalAlert ] = useState({})

  const [ watchingData, setWatchingData ] = useState()

  useEffect(() => {
    setLocalAlert({})
  }, [watchingData])

  const onSubmitPatient = (data) => {
    console.log("data", data)
    data.ssn = data.ssn.replace('-', '')
    setSsn(data.ssn)
    if (typeof data.birthDate.getFullYear === "function") {
        const year = data.birthDate.getFullYear()
        const month = data.birthDate.getMonth() + 1 // getMonth starts from 0
        const date = data.birthDate.getDate()
        data.birthDate = `${year}-${month}-${date}`
        console.log(data)
    }
    // Add patient
    fetch(
        `${process.env.REACT_APP_FAST_API_URI}/patients/add_patient`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
              "patient_ssn": data.ssn,
              "patient_first_name": data.firstName,
              "patient_last_name": data.lastName,
              "date_of_birth": data.birthDate,
              "sex": data.sex
          })
      }
    )
    .then(response => {
        if (response.ok) {
            console.log("response", response)
            setAlert({
              open: true,
              severity: "success",
              content: <div>
                <p>{t("alerts.addToSystemAlert.Patient_success")}</p>
              </div>,
              action: null
            })
            setStep(2)
            return response.json()
        } else if (response.status === 409) {
          const comp = <Link
            to='/patient'
            state={{patientSsn: data.ssn}}
          >
              {t("pages.AddPatient.SecondaryButton.label")}
          </Link>
          setLocalAlert({
            open: true,
            severity: "info",
            content: <div>
              <p>{t("alerts.addToSystemAlert.Patient_alreadyExists")}</p>
              {comp}
            </div>,
            action: null
          })
        } else {
            setLocalAlert({
              open: true,
              severity: "warning",
              content: <div>
                <p>{t("alerts.errorAlert.unknownError")}</p>
              </div>,
              action: null
            })
            throw new Error(`Error. Response code: ${response.status}`)
        }
    })
    .then(_ => {
      // Add subscription
      return fetch(
        `${process.env.REACT_APP_FAST_API_URI}/users/add_subscription`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
              "patient_ssn": data.ssn,
              "user_id": user.id
          })
        }
      )
    })
    .then(response => {
        if (response.ok) {
            console.log("response", response)
            return response.json()
        } else {
            throw new Error(`Error. Response code: ${response.status}`)
        }
    },
    err => {
      console.log("err")
      console.log(err)
      setLocalAlert({
          open: true,
          severity: "warning",
          content: <div>
            <p>{t("alerts.errorAlert.unexpectedError")}</p>
          </div>,
          action: null
        })
        throw new Error(`Fetch error. ${err}`)
      }
    )
    .catch(error => {
      console.log("error:: ")
      console.log(error)
    })
  }

  return (
    <div className="flexbox-page">
      <h1>{t("pages.AddPatient.h1")}</h1>
        <AddPatientForm onSubmit={onSubmitPatient} setWatchingData={setWatchingData} disabledForm={step>1} patientSsn={ssn}/>
      {step === 2 && 
        <AddDiagnosisForm accessToken={accessToken} ssn={ssn} navigateToPatient={true} setGoToPatientReady={setGoToPatientReady} />
      }
      <AlertOnPage localAlert={localAlert}/>
      {goToPatientReady ?
        <SecondaryButton component={Link} to="/patient" state={{patientSsn:ssn}} label={t("pages.AddPatient.SecondaryButton.label")}/>
      : <></>}
    </div>
  )
}


export default AddPatient