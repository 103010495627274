import React, { useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import {availableLanguages} from '../../i18nextConf'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, styled } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18next from 'i18next'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function LanguageSwitchMobile({openMobile, setMobileOpen}) {

  const handleLanguage = (language) => {
    if (i18next.language !== language) {
      setMobileOpen(false)
      i18next.changeLanguage(language)
    }
  }

  const { t } = useTranslation()

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false)
  }, [openMobile])

  return (
    <div className='language-switch-mobile'>
      <Button
        className="navigation-menu-list-item"
        onClick={handleExpandClick}
        disableRipple={true}
      >
        {t("components.navigation.LanguageSwitch.changeLanguageButton").toLocaleUpperCase()}
        <ExpandMore
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Button>
      <Collapse in={expanded}>
        {availableLanguages.map(language => (
          language !== i18next.language ?
            <MenuItem onClick={() => handleLanguage(language)} className="navigation-menu-list-item">
            {t(`languages.${language}`).toUpperCase()}
            </MenuItem>
            :
            <></>
        ))}
      </Collapse>
    </div>
  )
}
