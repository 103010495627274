import React from 'react'
import { SelectOne, SelectMultiple } from '../fields/SelectComponents'
import TextFieldComponent from '../fields/TextFieldComponent'
import RadioButtonComponent from '../fields/RadioButtonComponent'
import SwitchComponent from '../fields/SwitchComponent'
import AutocompleteComponent from '../fields/AutocompleteComponent'
import DatePickerComponent from '../fields/DatePickerComponent'
import DateTimePickerComponent from '../fields/DateTimePickerComponent'
import { PrimaryButton, SimpleButton } from '../general/Buttons'
import { InformationIcon } from '../general/InformationIcon'
import '../../styles/forms/Form.css'
import '../../styles/forms/Components.css'
import { Stack } from '@mui/material'

export default function Form({title, inputFields, onSubmit, control, handleSubmit, register, errors, submitButtonLabel, disabledForm}) {

    function getTextFieldComponent(values, disabledForm) {
        return (
            <TextFieldComponent
                name={values.name}
                required={values.required}                  // Optional
                requiredMessage={values.requiredMessage}    // Optional
                pattern={values.pattern}                    // Optional
                patternMessage={values.patternMessage}      // Optional
                max={values.max}                            // Optional
                maxMessage={values.maxMessage}              // Optional
                min={values.min}                            // Optional
                minMessage={values.minMessage}              // Optional
                maxLength={values.maxLength}                // Optional
                maxLengthMessage={values.maxLengthMessage}  // Optional
                label={values.label}
                type={values.type}
                startAdornment={values.startAdornment}      // Optional
                endAdornment={values.endAdornment}          // Optional
                className={values.className}                // Optional
                placeholder={values.placeholder}            // Optional
                disabled={disabledForm}
                warningCheck={values.warningCheck}          // Optional
                warning={values.warning}                    // Optional
                // control, register, and errors must be sent in to TextFieldComponent
                control={control}
                register={register}
                errors={errors}
            />
        )
    }


    function getSelectOne(values, disabledForm) {
        return (
            <SelectOne
                name={values.name}
                label={values.label}
                required={values.required}                  // Optional
                requiredMessage={values.requiredMessage}    // Optional
                canBeEmpty={values.canBeEmpty}
                items={values.items}
                disabled={disabledForm}
                // control, register, and errors must be sent in to TextFieldComponent
                control={control}
                register={register}
                errors={errors}
                displayCode={values.displayCode}
                diagnosis={values.diagnosis}
            />
        )
    }


    function getSelectMultiple(values, disabledForm) {
        return (
            <SelectMultiple
                name={values.name}
                label={values.label}
                required={values.required}                  // Optional
                requiredMessage={values.requiredMessage}    // Optional
                items={values.items}
                disabled={disabledForm}
                // control, register, and errors must be sent in to TextFieldComponent
                control={control}
                register={register}
                errors={errors}
            />
        )
    }


    function getRadioButtonsComponent(values, disabledForm) {
        return (
            <RadioButtonComponent
                name={values.name}
                label={values.label}
                required={values.required}                  // Optional
                requiredMessage={values.requiredMessage}    // Optional
                defaultValue={values.defaultValue}
                items={values.items}
                disabled={disabledForm}
                warningCheck={values.warningCheck}          // Optional
                warning={values.warning}                    // Optional
                // control, register, and errors must be sent in to TextFieldComponent
                control={control}
                register={register}
                errors={errors}
            />
        )
    }


    function getSwitchComponent(values, disabledForm) {
        return (
            <SwitchComponent
                name={values.name}
                defaultValue={values.defaultValue}
                disabled={disabledForm}
                // control must be sent in to TextFieldComponent
                control={control}
            />
        )
    }


    function getAutocompleteSingleComponent(values) {
        return (
            <AutocompleteComponent
                name={values.name}
                label={values.label}
                placeholder={values.placeholder}
                items={values.items}
                disabled={disabledForm}
                required={values.required}                  // Optional
                defaultValue={values.defaultValue}          // Optional
                requiredMessage={values.requiredMessage}
                multiple={false}
                // control, register must be sent in to TextFieldComponent
                control={control}
                register={register}
                displayCode={values.displayCode}
                diagnosis={values.diagnosis}
            />
        )
    }

    function getAutocompleteMultipleComponent(values) {
        return (
            <AutocompleteComponent
                name={values.name}
                label={values.label}
                placeholder={values.placeholder}
                items={values.items}
                disabled={disabledForm}
                required={values.required}                  // Optional
                requiredMessage={values.requiredMessage}    // Optional
                defaultValue={values.defaultValue}          // Optional
                multiple={true}
                // control, register must be sent in to TextFieldComponent
                control={control}
                register={register}
                errors={errors}
                displayCode={values.displayCode}
                diagnosis={values.diagnosis}
            />
        )
    }


    function getDatePickerComponent(values, disabledForm) {  
    // - (Optional) buttonLabel: string, if set a button will show next to the field to turn of readOnly
        return (
            <div className={values.datePickerDivClassName ?? null}>
                <DatePickerComponent
                    name={values.name}
                    label={values.label}
                    required={values.required}                  // Optional
                    requiredMessage={values.requiredMessage}    // Optional
                    defaultValue={values.defaultValue}
                    readOnly={values.readOnly}
                    placeholder={values.placeholder}            // Optional
                    disabled={disabledForm}
                    birthDate={values.birthDate}                // Optional
                    // control, register, and errors must be sent in to TextFieldComponent
                    control={control}
                    register={register}
                    errors={errors}
                />
                {values.buttonLabel && <SimpleButton id={values.buttonId} type="button" onClick={values.onClick} label={values.buttonLabel}/>}
            </div>
        )
    }


    function getDateTimePickerComponent(values, disabledForm) {
        return (
            <DateTimePickerComponent
                name={values.name}
                label={values.label}                        // Optional
                required={values.required}                  // Optional
                requiredMessage={values.requiredMessage}    // Optional
                disabled={disabledForm}
                birthDate={values.birthDate}                // Optional
                // control, register, and errors must be sent in to TextFieldComponent
                control={control}
                register={register}
                errors={errors}
            />
        )
    }


    const inputTypeTranslation = {
        "Autocomplete": getAutocompleteSingleComponent,
        "Autocomplete - select multiple": getAutocompleteMultipleComponent,
        "Checkbox": undefined,
        "Date": getDatePickerComponent,
        "Date time": getDateTimePickerComponent,
        "Double field": getTextFieldComponent,
        "Dropdown (select)": getSelectOne,
        "Dropdown (select multiple)": getSelectMultiple,
        "Integer field": getTextFieldComponent,
        "Radio": getRadioButtonsComponent,
        "Switch (true/false)": getSwitchComponent,
        "Textfield": getTextFieldComponent
    }

    return (
        <div>
            {/* Props is an array of objects with values for each input type */}
            {/* Each input type should have a label */}
            {title && <h1>{title}</h1> }
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                {inputFields.map((item) => (
                    <div className='form-item'>
                        <Stack direction="row" className='form-item-label-info'>
                            <label>{item.fieldLabel}</label>
                            {item.information && <InformationIcon text={item.information}/>}
                        </Stack>
                        {inputTypeTranslation[item.inputType](item, disabledForm)}
                    </div>
                ))}
                <PrimaryButton type="submit" label={submitButtonLabel} disabled={disabledForm}/>
            </form>
        </div>
    )
}
