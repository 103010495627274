import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { PrimaryButton } from '../components/general/Buttons';
import { Avatar, Paper, Chip, Typography } from '@mui/material';
import Moment from 'moment'
import { Person } from '@mui/icons-material/';
import '../styles/Profile.css'
import { InformationIcon } from '../components/general/InformationIcon';
import { useTranslation } from 'react-i18next'

function Profile({ user }) {
    const { logout } = useAuth0();
    const { t } = useTranslation()
    const role = user ? user.role === "doctor" ? t("pages.Profile.role.doctor") : (user.role === "labtech" ? t("pages.Profile.role.labtech") : t("pages.Profile.role.other")) : null

    return (
        <div>
            <h1>{t("pages.Profile.h1")}</h1>
            <Paper className='profile-paper' elevation={3}>
            {user &&
                <div className='profile-paper-content'>
                    <Chip icon={<Person />} label={role} className='profile-role-chip'/>
                    <Avatar 
                        alt={user.user_name} 
                        src={user.picture}
                        sx={{ width: 56, height: 56 }}
                    />
                    <Typography variant="h4">{user.user_name}</Typography>
                    <Typography variant="h6">{user.email}</Typography>
                    {!user.email_verified ? 
                        <div className='profile-verification-info'>
                            <p>{t("pages.Profile.email_verified.false.p")}</p>
                            <InformationIcon text={t("pages.Profile.email_verified.false.InformationIcon.text")}/>
                        </div> :
                        <div className='profile-verification-info'>
                        <p>{t("pages.Profile.email_verified.true.p")}</p>
                    </div>
                    }
                    <p className='profile-text-bold'>{t("pages.Profile.created")}</p>
                    <p className='profile-text-date'>{Moment(user.created_at).format('YYYY-MM-DD HH:mm')}</p>
                    <p className='profile-text-bold'>{t("pages.Profile.lastLogIn")}</p>
                    <p className='profile-text-date'>{Moment(user.last_login).format('YYYY-MM-DD HH:mm')}</p>
                    
                    <PrimaryButton onClick={() => logout()} label={t("pages.Profile.logOutButton.label")}/>
                </div>
            }
            </Paper>
        </div>
        
    )
}

export default Profile