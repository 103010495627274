import React from "react";
import './../../styles/Navigation.css';
import logo from './../../images/logo.png';
import { Link } from "react-router-dom";

export default function Logo() {
    return (
        <div className="navigation-logo">
        <Link to="/" >
            <img
                src={logo}
                alt="Logo"
                className="navigation-logo-img"
            />
        </Link>
    </div>
    );
}
