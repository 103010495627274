import React from 'react'
import { Avatar, Tooltip } from '@mui/material';
import { QuestionMark } from '@mui/icons-material/';
import '../../styles/General.css'

function InformationIcon({ text }) {


  return (
    <div>
        <Tooltip componentsProps={{ tooltip: { sx: { fontSize: "14px" }}}} placement="top-start" title={ text }>
            <Avatar className='informationicon-avatar'><QuestionMark sx={{ fontSize: 10 }}/></Avatar>
        </Tooltip>
    </div>
  )
}

export { InformationIcon }