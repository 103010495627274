import * as React from 'react'
import {
  TextField,
  Autocomplete,
  Chip
} from '@mui/material'
import "../../styles/forms/Components.css"
import { displayWithCode } from './Utils'

export default function AutocompleteSingle({items, onChange, label, placeholder, value, displayCode, diagnosis}) {
    // Autocomplete component where the user can only select one value

    // The parameter props includes:
    // - name: string, name of input field
    // - label: string, input label text to be displayed in the input field
    // - items: array, a list of options of type object to choose from
    // - placeholder: string, placeholder text for input window
    // - multiple: boolean, should user be able to pick multiple items or not
    // - control, register

    return <div>
        <Autocomplete
            multiple={false}
            options={items}
            getOptionLabel={(option) => { if (displayCode) { return displayWithCode(option.id, option.description, diagnosis)} return option.description}}
            onChange={(_, value) => onChange(value)}
            value={value}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                <Chip
                    label={option.description}
                    {...getTagProps({ index })}
                />
                ))
            }
            style={{ width: 500 }}
            renderInput={(params) => (
            <TextField 
                {...params} 
                label={label} 
                placeholder={placeholder}
                className='autocomplete'
            />
            )}
        />
    </div>
}
