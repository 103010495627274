import React from 'react'
import Popover from '@mui/material/Popover'
import { Link } from "react-router-dom"
import { PrimaryButton } from '../general/Buttons'
import { useTranslation } from 'react-i18next'
import { structionSsn } from '../general/Utils'

export default function AddPatientPopUp({ popupState, setPopupState, patientSsn }) {
    // Popup to be navigated to the add patient page when the user searched for a ssn that does not exist

    // The Parameter props includes:
    // - popupState: boolean, if the popup is open
    // - setPopupState: func, set the popupstate
    // - patientSsn: string, ssn the user searched for to automatically fill in the form

    const { t } = useTranslation()
    const handleClose = () => {
        setPopupState(false)
    }

    const id = popupState ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            className={id}
            open={popupState}
            onClose={handleClose}
            anchorReference={"none"}
        >
            <div className='search-field-popup'>
                <h1>{t("components.addPatient.AddPatientPopUp.Popover.header")}</h1>
                <p>{t("components.addPatient.AddPatientPopUp.Popover.paragraph",{"ssn": structionSsn(patientSsn)})}</p>
                <PrimaryButton 
                    onClick={handleClose} 
                    component={Link} 
                    to="/add_patient" 
                    label={t("components.addPatient.AddPatientPopUp.Popover.buttonLabel")} 
                    state={{patientSsn: patientSsn}}
                />
            </div>
        </Popover>
    );
}