import React, { useState, useEffect } from "react"
import {Box, Tabs, Tab, Typography, Backdrop, CircularProgress} from '@mui/material'
import PropTypes from "prop-types"
import ReportStepper from "./ReportStepper"
import { Link } from "react-router-dom"
import Popover from '@mui/material/Popover'
import StartCalculation from "./StartCalculation"
import { PrimaryButton, SecondaryButton } from "../general/Buttons"
import "../../styles/tables/PatientTabs.css"
import Moment from 'moment'
import AlertOnPage from "../alerts/AlertOnPage"
import { useTranslation } from 'react-i18next'

function TabPanel({ children, value, index, ...other }) {

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other} 
      >
        <Box p={4}>{children}</Box>
      </Typography>
    )
}
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    }

function sortDataList(dataList, sortKey, id) { 
    // Sorts dicts in list in date order and sets new cleaned "date_time" key.
    // If id is passed it will also check if the record is used in the 
    // latest recommendation and add the "used" key if it is.
    // The used key is used to display that the lab result or hr was 
    // used in the latest recommendation.
    dataList.sort(function(o1,o2){
        if (o1[sortKey] > o2[sortKey] )       return -1
        else if (o1[sortKey] < o2[sortKey] )  return 1
        else                                  return 0
    })
    dataList.forEach(data => {
        data["date_time"] = Moment(data[sortKey]).format('YYYY-MM-DD HH:mm')
        if (id) {
            if (data["id"] == id) {
                data["used"] = true
            }
        }
    })
    return dataList
}

function addReportReferences(recommendations, report, type, newName) {
    // Adds a date reference to used report (labresult or hr)
    recommendations.forEach(recommendation => {
        recommendation[newName] = report.find(obj => {
            return obj["id"] === recommendation[type];
          })["date_time"]
    })
    return recommendations
}

function checkIfLatestDate(recommendation, labResult, healthRecord) {
    // Check if the recommendation was calculated with the latest
    // lab result and/or hr. If not a latest.. key is created with
    // the data of the newer record
    if (recommendation["lab_result_id"] != labResult["id"]) {
        recommendation["latest_lab_result_date"] = labResult["date_time"]
    }
    if (recommendation["health_id"] != healthRecord["id"]) {
        recommendation["latest_health_record_date"] = healthRecord["date_time"]
    }
    return recommendation
}

function PatientTabs({ accessToken, diagnosis, patientSsn, patientFirstName, patientLastName, patientSex, user }) {

    const { t } = useTranslation()

    const [recommendations, setRecommendations] = useState([])
    const [healthRecords, setHealthRecords] = useState([])
    const [healthRecordParams, setHealthRecordParams] = useState([])
    const [labResults, setLabResults] = useState([])
    const [labResultParams, setLabResultParams] = useState([])
    const [popupOpen, setPopupOpen] = useState(false)

    const [isCalculating, setIsCalculating] = useState(false)
    const [calculationSucceeded, setCalculationSucceeded] = useState(false)
    const [localAlert, setLocalAlert] = useState({})

    function handleClick() {
        setPopupOpen(true)
        setLocalAlert({})
    }

    const handleClose = () => {
        setPopupOpen(false)
    }

    const popupId = popupOpen ? 'simple-popover' : undefined;

    function fetchParams(url, setFunction) {
        fetch(url,{
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    },
            })
            .then(response => {
                console.log(response.status)
                if (response.ok) {
                    console.log("response", response)
                    return response.json()
                } else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            })
            .then(responseData => {
                setFunction(responseData)
                }
            )
            .catch(error => {
                console.log("error:: ")
                console.log(error)
            })
    }

    function fetchParamsAndData() {
        fetchParams(
            `${process.env.REACT_APP_FAST_API_URI}/lab/get_lab_result_parameters/${diagnosis.id}?language=${t("languages.queryParam")}`,
            setLabResultParams
        )
        fetchParams(
            `${process.env.REACT_APP_FAST_API_URI}/health-records/get_health_record_parameters/${diagnosis.id}?language=${t("languages.queryParam")}`,
            setHealthRecordParams
        )
        fetchData()
    }

    function fetchData() {
        fetch(`${process.env.REACT_APP_FAST_API_URI}/algorithm/get_recommendations/?patient_ssn=${patientSsn}&diagnosis_id=${diagnosis.id}&language=${t("languages.queryParam")}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                },
        })
            .then(response => {
                console.log(response.status)
                if (response.ok) {
                    console.log("response", response)
                    return response.json()
                } else if (response.status == 404) {
                    return []
                } else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            })
            .then(responseData => {
                console.log(responseData)
                var sortedRecommendations = responseData.length > 0 ? sortDataList(responseData, "run_date") : responseData
                fetch(`${process.env.REACT_APP_FAST_API_URI}/health-records/get_health_records/?patient_ssn=${patientSsn}&diagnosis_id=${diagnosis.id}`,{
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        },
                    })
                    .then(response => {
                        console.log(response.status)
                        if (response.ok) {
                            console.log("response", response)
                            return response.json()
                        } else if (response.status == 404) {
                            return []
                        } else {
                            throw new Error(`Error. Response code: ${response.status}`)
                        }
                    })
                    .then(responseData => {
                        var id = sortedRecommendations.length > 0 ? sortedRecommendations[0]["health_id"] : null
                        var sortedResponse = responseData.length > 0 ? sortDataList(responseData, "input_date", id) : responseData
                        sortedRecommendations = addReportReferences(sortedRecommendations, sortedResponse, "health_id", "health_record_date")
                        setHealthRecords(sortedResponse)
                        return sortedResponse
                    })
                    .then(_healthRecord => {
                        fetch(`${process.env.REACT_APP_FAST_API_URI}/lab/get_lab_results/?patient_ssn=${patientSsn}&diagnosis_id=${diagnosis.id}`,{
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                },
                            })
                            .then(response => {
                                console.log(response.status)
                                if (response.ok) {
                                    console.log("response", response)
                                    return response.json()
                                } else if (response.status == 404) {
                                    var dict = {}
                                    dict['lab_results'] = []
                                    return dict
                                } else {
                                    throw new Error(`Error. Response code: ${response.status}`)
                                }
                            })
                            .then(responseData => {
                                var id = sortedRecommendations.length > 0 ? sortedRecommendations[0]["lab_result_id"] : null
                                var sortedResponse = responseData.length > 0 ? sortDataList(responseData, "lab_date", id) : responseData
                                sortedRecommendations = addReportReferences(sortedRecommendations, sortedResponse, "lab_result_id", "lab_result_date")
                                setLabResults(sortedResponse)
                                if (sortedRecommendations.length > 0) {
                                    sortedRecommendations[0] = checkIfLatestDate(sortedRecommendations[0], sortedResponse[0], _healthRecord[0])
                                }
                                setRecommendations(sortedRecommendations)
                            })
                            .catch(error => {
                                console.log("error:: ")
                                console.log(error)
                            })
                            .then(
                                setIsCalculating(false)
                            )
                    })
                    .catch(error => {
                        console.log("error:: ")
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log("error:: ")
                console.log(error)
            })
    }

    useEffect(() => {
        fetchParamsAndData()
    }, [diagnosis.id, patientSsn])

    const [tab, setTab] = useState(0)


    useEffect(() => {
        setPopupOpen(false)
        if (!isCalculating && calculationSucceeded) {
            fetchData()
        }
    }, [isCalculating])



    function handleChange(event, newValue) {
        setTab(newValue)
        setLocalAlert({})
    }
    return <div >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
                className="patient-tabs"
                value={tab} 
                onChange={handleChange} 
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs patient"
            >
                <Tab label={t("components.tables.PatientTabs.tabLabel.recommendations")} {...{id: `scrollable-auto-tab-0`, "aria-controls": `scrollable-auto-tabpanel-0`}}/>
                <Tab label={t("components.tables.PatientTabs.tabLabel.healthRecords")} {...{id: `scrollable-auto-tab-1`, "aria-controls": `scrollable-auto-tabpanel-1`}}/>
                <Tab label={t("components.tables.PatientTabs.tabLabel.labResults")} {...{id: `scrollable-auto-tab-2`, "aria-controls": `scrollable-auto-tabpanel-2`}}/>
                <Tab label={t("components.tables.PatientTabs.tabLabel.responseReports")} {...{id: `scrollable-auto-tab-2`, "aria-controls": `scrollable-auto-tabpanel-2`}}/>
            </Tabs>
        </Box>
        <TabPanel className="patient-tabs-panel-box" value={tab} index={0}>
            {recommendations.length > 0 ? <ReportStepper steps={recommendations} type="recommendation"/> : <p>{t("components.tables.PatientTabs.recommendationTab.noRecommendationsParagraph")}</p>}
            <PrimaryButton 
                onClick={handleClick}
                label={recommendations.length > 0 ? t("components.tables.PatientTabs.recommendationTab.startCalcButtonLabel.new") : t("components.tables.PatientTabs.recommendationTab.startCalcButtonLabel.first") }
                extraClassName="start-calc-button"
            />
            {recommendations.length > 0 && <SecondaryButton
                label={t("components.tables.PatientTabs.recommendationTab.addResponseButtonLabel")}
            />}
        </TabPanel>
        <TabPanel className="patient-tabs-panel-box" value={tab} index={1}>
            {
                healthRecords.length > 0 ? 
                <ReportStepper steps={healthRecords} type="health record" params={healthRecordParams} diagnosisId={diagnosis.id} recommendationsExist={recommendations.length > 0}/> : 
                <p>{t("components.tables.PatientTabs.healthrecordTab.noHealthrecordsParagraph")}</p>
            }
            <PrimaryButton 
                component={Link}
                to="/add_health_record" 
                label={t("components.tables.PatientTabs.healthrecordTab.addHRButtonLabel")}
                state={{patientSsn: patientSsn, patientFirstName: patientFirstName, patientLastName: patientLastName, patientSex: patientSex, diagnosisId: diagnosis.id, diagnosisDescription: diagnosis.description, user: user, previousHealthRecord:healthRecords[0]}}
            />
                
        </TabPanel>
        <TabPanel className="patient-tabs-panel-box" value={tab} index={2}>
            {
                labResults.length > 0 ? 
                <ReportStepper steps={labResults} type="lab result" params={labResultParams} diagnosisId={diagnosis.id} recommendationsExist={recommendations.length > 0}/> : 
                <p>{t("components.tables.PatientTabs.labresultTab.noLabresultsParagraph")}</p>
            }
            <PrimaryButton 
                component={Link}
                to="/add_lab_result" 
                label={t("components.tables.PatientTabs.labresultTab.addLRButtonLabel")}
                state={{patientSsn: patientSsn, patientFirstName: patientFirstName, patientLastName: patientLastName, patientSex: patientSex, diagnosisId: diagnosis.id, diagnosisDescription: diagnosis.description, user: user}}
            />
        </TabPanel>
        <TabPanel className="patient-tabs-panel-box" value={tab} index={3}>
            <p>Placeholder for response reports.</p>
        </TabPanel>
        <Popover
            id={popupId}
            open={popupOpen}
            onClose={handleClose}
            anchorReference={"none"}
        >
            <div className="start-calculation-popup">
                <StartCalculation
                    accessToken={accessToken}
                    patientSsn={patientSsn}
                    patientFirstName={patientFirstName}
                    patientLastName={patientLastName}
                    patientSex={patientSex}
                    diagnosis={diagnosis}
                    healthRecords={healthRecords}
                    labResults={labResults}
                    user={user}
                    setIsCalculating={setIsCalculating}
                    setLocalAlert={setLocalAlert}
                    setCalculationSucceeded={setCalculationSucceeded} />
            </div>
        </Popover>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isCalculating}
            onClick={() => setIsCalculating(false)}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        { !popupOpen ? <AlertOnPage localAlert={localAlert} /> :
        <></>
}
    </div>
}

export default PatientTabs