import React, { useState } from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Logo from './Logo'
import NavigationSearchField from "./NavigationSearchField"
import NavigationSearchFieldMobile from "./NavigationSearchFieldMobile"
import './../../styles/Navigation.css'
import { Link } from "react-router-dom"
import LanguageSwitch from "./LanguageSwitch"
import LanguageSwitchMobile from "./LanguageSwitchMobile"

const drawerWidth = 240

export default function Navigation({ accessToken, window, navItems, hrefs }) {
  
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MAVATAR
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={item} disablePadding onClick={handleDrawerToggle}>
            <Link to={hrefs[index]} className="navigation-menu-list-item">
              {item}
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <LanguageSwitchMobile openMobile={mobileOpen} setMobileOpen={setMobileOpen}/>
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined


  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" className="navigation" position="sticky" >
        <Toolbar className="navigation-toolbar">
          <Box
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
            <Logo />
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            {navItems.map((item, index) => (
              <Link to={hrefs[index]} key={item} className="navigation-menu-button">
                {item}
              </Link>
            ))}
          
          </Box>
          <NavigationSearchField 
            accessToken={accessToken}
            divClassName="navigation-search-non-mobile"
          />
          <LanguageSwitch />
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth
            }
          }}
        >
          <NavigationSearchFieldMobile
            accessToken={accessToken}
            divClassName="navigation-search-mobile"
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
          />
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}
