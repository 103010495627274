import { Alert, AlertTitle, Snackbar } from '@mui/material'
import useAlert from './useAlert'

const ALERT_TIME = 7000

export default function AlertPopup() {
  const { alert, closeAlert } = useAlert()

  return (
        <Snackbar open={alert.open} autoHideDuration={ALERT_TIME} onClose={closeAlert}>
            <Alert onClose={closeAlert} severity={alert.severity} variant="standard" sx={{ width: "auto" }} action={alert.action}>
                {alert.title? <AlertTitle>{alert.title}</AlertTitle> : <></>}
                {alert.content}
            </Alert>
        </Snackbar>
    )
}
