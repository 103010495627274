

function structionSsn(ssn){
    return `${ssn.substring(0,8)}-${ssn.substring(8,12)}`
}

function upperCaseName(firstName, lastName) {
    const firstNameList = firstName.split(" ").map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
    const lastNameList = lastName.split(" ").map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
    return firstNameList.join(' ') + ' ' + lastNameList.join(' ')
}

function sortDataList(dataList, sortKey) { 
    // Sorts dicts in list in alphabetical order 
    dataList.sort(function(o1,o2){
        if (o1[sortKey] < o2[sortKey] )       return -1
        else if (o1[sortKey] < o2[sortKey] )  return 1
        else                                  return 0
    })
    return dataList
}

function fixIcd10Code(code) {
    return code.replace("_", ".")
}

function displayWithCode(id, description, diagnosis) {
    if (diagnosis) {
        return "[" + fixIcd10Code(id) + "] " + description
    }
    return "[" + id + "] " + description
}

export { structionSsn, upperCaseName, sortDataList, fixIcd10Code, displayWithCode }