import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ReportForm from "../components/forms/ReportForm"
import PatientInformationBox from "../components/general/PatientInformationBox"
import useAlert from '../components/alerts/useAlert'
import AlertOnPage from "../components/alerts/AlertOnPage"
import i18next from "i18next"
import { useTranslation } from 'react-i18next'

function AddHealthRecord({accessToken}) {
    let navigate = useNavigate()
    let location = useLocation()
    const { t } = useTranslation()
    const patientSsn = location.state.patientSsn
    const patientSex = location.state.patientSex
    const patientFirstName = location.state.patientFirstName
    const patientLastName = location.state.patientLastName
    const diagnosisId = location.state.diagnosisId
    const diagnosisDescription = location.state.diagnosisDescription
    const previousHealthRecord = location.state.previousHealthRecord

    const { setAlert } = useAlert()
    const [localAlert, setLocalAlert] = useState({})
    const [localAlertPrefilled, setLocalAlertPrefilled] = useState({})

    useEffect(() => {
        if(previousHealthRecord){
            setLocalAlertPrefilled({   
                open: previousHealthRecord != null,
                severity: "info",
                content: <div>
                  <p>{t("alerts.prefilledFormAlert.info")} {previousHealthRecord["date_time"]}</p>
                </div>,
                action: null
                })
        }
    }, [previousHealthRecord])

    const onSubmit = (data) => {
        console.log("data", data)
        for(var key in data) {
            if (data[key] instanceof Array) {
                data[key] = Object.entries(data[key]).map( ([_, value]) => value.id)
            }
        }
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "patient_ssn": patientSsn,
                "diagnosis_id": diagnosisId,
                "params": data
            })
        }
        fetch(
            `${process.env.REACT_APP_FAST_API_URI}/health-records/add_health_record`,
            requestOptions
        )
        .then(response => {
            if (response.ok) {
                console.log("response", response)
                setAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "success",
                    content: <div>
                      <p>{t("alerts.addToSystemAlert.HR_success")}</p>
                    </div>,
                    action: null
                  }))
                  navigate("/patient", {state: {patientSsn: patientSsn, diagnosisId: diagnosisId, diagnosisDescription: diagnosisDescription}})
                return response.json()
            } else {
                setLocalAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "warning",
                    content: <div>
                      <p>{t(("alerts.errorAlert.unknownError"))}</p>
                    </div>,
                    action: null
                  }))
                throw new Error(`Error. Response code: ${response.status}`)
            }
        },
        err => {
            console.log("err")
            console.log(err)
            setLocalAlert(prevState => ({
                ...prevState,
                open: true,
                severity: "warning",
                content: <div>
                  <p>{t("alerts.errorAlert.unexpectedError")}</p>
                </div>,
                action: null
              }))
              throw new Error(`Fetch error. ${err}`)
            }
        )
        .then(responseData => {
            console.log("responseData", responseData)
        })
        .catch(error => {
            console.log("error:: ")
            console.log(error)
          })
    }
    return (
        <div>
            <h1>{t("pages.AddHealthRecord.h1")} {diagnosisDescription}</h1>
            <PatientInformationBox patientSsn={patientSsn} patientSex={patientSex} patientFirstName={patientFirstName} patientLastName={patientLastName} patientNameHeader={false}/>
            <AlertOnPage localAlert={localAlertPrefilled}/>
            <ReportForm
                accessToken={accessToken}
                url={`${process.env.REACT_APP_FAST_API_URI}/health-records/get_health_record_parameters/${diagnosisId}?language=${t("languages.queryParam")}`}
                type="healthRecord"
                onSubmit={onSubmit}
                submitButtonLabel={t("pages.AddHealthRecord.ReportForm.submitButtonLabel")}
                previousRecord={previousHealthRecord}
                diagnosisId={diagnosisId}
            />
            <AlertOnPage localAlert={localAlert} />
        </div>
    )
}


export default AddHealthRecord