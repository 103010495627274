import { Box } from "@mui/system"
import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import Form from "../forms/Form"
import { useNavigate } from "react-router-dom"
import { Stack } from "@mui/material"
import useAlert from "../alerts/useAlert"
import AlertOnPage from "../alerts/AlertOnPage"
import i18next from "i18next"
import { useTranslation } from 'react-i18next'

export default function AddDiagnosisForm({ accessToken, ssn, navigateToPatient, setGoToPatientReady, setPatientTableUpdates}) {
    let navigate = useNavigate()
    const { setAlert } = useAlert()
    const { t } = useTranslation()

    var diagnosesSucceded = []
    var diagnosesExist = []
    var diagnosesFailed = []

    const { control, handleSubmit, register, formState: { errors }, watch } = useForm()

    const [diagnosisOptions, setDiagnosisOptions] = useState({id:0, description:"None"})
    
    const inputFields = [
        {
            fieldLabel: t("components.addPatient.AddDiagnosisForm.inputFields.field_label"),
            inputType: "Autocomplete - select multiple",
            name: "diagnosis-picker",
            label: t("components.addPatient.AddDiagnosisForm.inputFields.label"),
            required: true,
            placeholder: t("components.addPatient.AddDiagnosisForm.inputFields.placeholder"),
            items: diagnosisOptions,
            displayCode: true,
            diagnosis: true
        }
    ]

    const watchingData = watch("diagnosis-picker")

    const diagnosisLocalAlertsInit = {
        success: {},
        exist: {},
        failed: {},
        error: {}
    }

    const [diagnosisLocalAlerts, setDiagnosisLocalAlerts] = useState({
        success: {},
        exist: {},
        failed: {},
        error: {}
    })

    useEffect(() => {
        setDiagnosisLocalAlerts(diagnosisLocalAlertsInit)
        if (setGoToPatientReady !== undefined) {
            setGoToPatientReady(false)
        }
      }, [watchingData])

    async function fetchDiagnosisOptions() {
        fetch(`${process.env.REACT_APP_FAST_API_URI}/algorithm/get_diagnosis_information/?language=${t("languages.queryParam")}`, {
            headers: {
            Authorization: `Bearer ${accessToken}`,
            },
        })
            .then(response => {
                console.log(response.status)
                if (response.ok) {
                    console.log("response", response)
                    return response.json()
                } else {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            })
            .then(responseData => {
                console.log(responseData)
                setDiagnosisOptions(responseData)
            })
            .catch(error => {
                console.log("error:: ")
                console.log(error)
            })
    }

    const onSubmit = (data) => {
        console.log("data", data)
        Promise.all(
          data["diagnosis-picker"].map(row =>
            fetch(
              `${process.env.REACT_APP_FAST_API_URI}/patients/add_patient_diagnosis`,
              {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                    "accept": "application/json"
                },
                body: JSON.stringify({
                    "patient_ssn": ssn,
                    "diagnosis_id": row.id
                })
              }
            )
            .then(response => {
                console.log(response.status)
                if (response.ok) {
                    console.log("response", response)
                    diagnosesSucceded.push(row.description)
                    return response.json()
                } else if (response.status === 409) {
                    diagnosesExist.push(row.description)
                } else {
                    diagnosesFailed.push(row.description)
                    throw new Error(`Error. Response code: ${response.status}`)
                }
            })
          )
        )
        .then(() => {
            if(diagnosesFailed.length === 0 && diagnosesExist.length === 0) {
                setAlert(prevState => ({
                    ...prevState,
                    open: true,
                    severity: "success",
                    content: <div>
                      { diagnosesSucceded ? diagnosesSucceded.map((diagnosis) => (
                            <p>{diagnosis} {t("alerts.addToPatientAlert.success")}</p>
                        )): <></>}
                    </div>,
                    action: null
                  }))
                if (setPatientTableUpdates !== undefined) {
                    setPatientTableUpdates(prevState => !prevState)
                }
                if (navigateToPatient) {
                    navigate("/patient", {state: {patientSsn: ssn}})
                }
                if (setGoToPatientReady !== undefined) {
                    setGoToPatientReady(true)
                }
            }
            else {
                if (diagnosesSucceded.length > 0) {
                    setDiagnosisLocalAlerts(prevState => ({
                        ...prevState,
                        success: {
                            open: true,
                            severity: "success",
                            content: <div>
                            {diagnosesSucceded.map((diagnosis) => (
                                <p>{diagnosis} {t("alerts.addToPatientAlert.success")}</p>
                                ))}
                        </div>,
                        action: null
                    }
                    }))
                }
                if (diagnosesExist.length > 0) {
                    setDiagnosisLocalAlerts(prevState => ({
                        ...prevState,
                        exist: {
                            open: true,
                            severity: "info",
                            content: <div>
                            { diagnosesExist.map((diagnosis) => (
                                <p>{diagnosis} {t("alerts.addToPatientAlert.alreadyExists")}</p>
                                ))}
                        </div>,
                        action: null
                    }
                    }))
                }
                if (diagnosesFailed.length > 0) {
                    setDiagnosisLocalAlerts(prevState => ({
                        ...prevState,
                        failed: {
                            open: true,
                            severity: "info",
                            content: <div>
                            {diagnosesFailed.map((diagnosis) => (
                                <p>{diagnosis} {t("alerts.addToPatientAlert.failed")}</p>
                                ))}
                        </div>,
                        action: null
                    }
                    }))
                }
                if (setGoToPatientReady !== undefined) {
                    setGoToPatientReady(true)
                }
                throw new Error(`Error.`)
            }
        },
            err => {
                console.log("err")
                console.log(err)
                setDiagnosisLocalAlerts(prevState => ({
                    ...prevState,
                    error: {
                        open: true,
                        severity: "warning",
                        content: <div>
                            <p>{t("alerts.errorAlert.unexpectedError")}</p>
                        </div>,
                        action: null
                    }
                }))
                throw new Error(`Fetch error. ${err}`)
            }
        )
        .catch(error => {
          console.log("error:: ")
          console.log(error)
        })
      }

    useEffect(() => {
        fetchDiagnosisOptions()
    }, [])

    return (
        <Box>
            <Form
                title= {t("components.addPatient.AddDiagnosisForm.form.title")}
                inputFields={inputFields}
                onSubmit={onSubmit}
                control={control}
                handleSubmit={handleSubmit}
                register={register}
                errors={errors}
                submitButtonLabel={t("components.addPatient.AddDiagnosisForm.form.submitButtonLabel")}
            />
            <Stack spacing={2}>
            {
                Object.entries(diagnosisLocalAlerts).map( ([key, val]) => {
                    return (
                        <AlertOnPage localAlert={val} />
                    )
                })
            }
            </Stack>
        </Box>
    )
}