import React, { useEffect, useState } from "react"
import { Box, Typography } from '@mui/material'
import { SecondaryButton } from "../general/Buttons"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import "../../styles/tables/MedicationDisplay.css"

const blurFilter = {filter: "blur(5px)"}

export default function MedicationDisplay({ recommendation, first }) {

    const [newerExists, setNewerExists] = useState(false)
    const [blur, setBlur] = useState(false)
    const { t } = useTranslation()

    // Temp indicated drug stuff
    const [haveNotIndDrugs, setHaveNotIndDrugs] = useState(false)
    const notIndDrugs = ["M05BA02", "M01AE01", "M05BX05", "L01CX01", "P01BE05", "L01FB02", "L04AA26"]

    function handleClick() {
        setBlur(!blur)
    }

    useEffect(() => {
        const newer = ((first && ((recommendation['latest_health_record_date'] || recommendation['latest_lab_result_date']))? true : false))
        setNewerExists(newer)
        setBlur(newer)

        // Temp indicated drug stuff
        setHaveNotIndDrugs(false)
        if (!recommendation['medications'].map((med)=>med.medication_id).includes("L01AA01")){
            for (var i=0; i < recommendation['medications'].length; i++) {
                if (notIndDrugs.includes(recommendation['medications'][i].medication_id)){
                    setHaveNotIndDrugs(true)
                    break
                }
            }
        }
    }, [recommendation])

    return (
        <div className="medication-display">
            <Typography className="medication-display-h1">{t("components.tables.MedicationDisplay.topHeader")}</Typography>
            <div className="recommendation-data-source">
                <Box sx={{ display: 'flex' }}>
                    <Typography>
                        {t("components.tables.MedicationDisplay.recordLabel.healthRecord")}: {recommendation['health_record_date']}
                    </Typography>
                    {(newerExists && recommendation['latest_health_record_date']) &&
                        <Typography style={{color: "#f70000"}}>
                            {'\xa0'}
                            {t("components.tables.MedicationDisplay.latestDataDate", {date: recommendation['latest_health_record_date']})}
                        </Typography>
                    }
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography>
                        {t("components.tables.MedicationDisplay.recordLabel.labResult")}: {recommendation['lab_result_date']}
                    </Typography>
                    {(newerExists && recommendation['latest_lab_result_date']) &&
                        <Typography style={{color: "#f70000"}}>
                            {'\xa0'}
                            {t("components.tables.MedicationDisplay.latestDataDate", {date: recommendation['latest_lab_result_date']})}
                        </Typography>
                    }
                </Box>
                {newerExists && <Typography style={{ fontWeight: 600 }}>{t("components.tables.MedicationDisplay.newerExistParagraph")}</Typography>}
            </div>
            <Box style={blur ? blurFilter : {}}>
                {recommendation['error_message'] === null ?
                    <div>
                        <Typography className="medication-display-h1">{t("components.tables.MedicationDisplay.recommendationHeader")}</Typography>
                            <TableContainer className="medication-table-container" component={Paper}>
                            <Table  size="small" >
                                <TableHead >
                                <TableRow>
                                    <TableCell className="medication-table-header-cell" ><strong></strong></TableCell>
                                    <TableCell className="medication-table-header-cell"><strong>{t("components.tables.MedicationDisplay.table.cellHeader.name")}</strong></TableCell>
                                    <TableCell className="medication-table-header-cell"><strong>{t("components.tables.MedicationDisplay.table.cellHeader.code")}</strong></TableCell>
                                    <TableCell className="medication-table-header-cell"><strong>{t("components.tables.MedicationDisplay.table.cellHeader.relativeConfidence")}</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    { recommendation['medications'].map((med) => (
                                        <TableRow
                                            key={med.recommendation_order}
                                        >
                                        <TableCell className="medication-table-cell" sx={{borderBottom: "transparent"}}>
                                            {med.recommendation_order}
                                        </TableCell>
                                        <TableCell className="medication-table-cell" sx={{borderBottom: "transparent"}}>
                                            {
                                                (notIndDrugs.includes(med.medication_id) && haveNotIndDrugs) ?
                                                med.description.charAt(0).toUpperCase() + med.description.slice(1).toLowerCase() + "*" :
                                                med.description.charAt(0).toUpperCase() + med.description.slice(1).toLowerCase()
                                            }
                                        </TableCell>
                                        <TableCell className="medication-table-cell" sx={{borderBottom: "transparent"}}>
                                            {med.medication_id}
                                        </TableCell>
                                        <TableCell className="medication-table-cell" sx={{borderBottom: "transparent"}}>
                                            {med.relative_confidence.toLocaleString(t("localeStringFormatter"))}
                                        </TableCell>
                                        
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <p>{haveNotIndDrugs}</p>
                        {haveNotIndDrugs && <Typography className="medication-table-comment">* The drug is not indicated for the diagnosis</Typography>}
                        {/* <Typography className="medication-table-comment-header">{t("components.tables.MedicationDisplay.commentHeader")}</Typography>
                        <Typography className="medication-table-comment">{recommendation['medications_comment']}</Typography> */}
                    </div> :
                    <div>
                        <Typography className="medication-display-h1">{t("components.tables.MedicationDisplay.errorHeader")}</Typography>
                        <Typography className="medication-table-error">{recommendation['error_message']}</Typography>
                    </div>
                }
            </Box>
            {newerExists && 
                <SecondaryButton 
                    onClick={handleClick}
                    label={blur ? t("components.tables.MedicationDisplay.blurButton.show") : t("components.tables.MedicationDisplay.blurButton.hide") }
                    red={true}
                />
            }
        </div>
    )
}