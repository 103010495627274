import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import TextFieldComponent from '../fields/TextFieldComponent'
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'


export default function SearchField({ 
    accessToken, divClassName, textFieldClassName, iconClassName, onSubmit, setPopupState, noHelperText, disabled, handleCloseSearch, setPatientSsn, mobileOpen, setMobileOpen,
}) {
    // Search field component where the user can search for patients

    // The Parameter props includes:
    // - divClassName: string, classname for wrapping div
    // - textFieldClassName: string, classname for text field
    // - iconClassName: string, classname for icon
    // - onSubmit: func, executed when ssn is submitted
    // - setPopupState: func, sets add patient popup state
    // - noHelperText: boolean, determines if helper text is displayed
    // - disabled: boolean, disables text fields if true
    // - handleClosePopup: handle search field if open in a popuup [optional]
    // - setPatientSsn: func, sets patients ssn
    // - openMobile: boolean, determines if mobile navigation is open
    // - setMobileOpen: func, set openMobile

    let navigate = useNavigate()
    const { t } = useTranslation()
    
    const { control, handleSubmit, register, formState: { errors }, reset } = useForm({defaultValues:{
        ssn: ''
    }})

    var ssn = ''
    useEffect(() => {
        reset({ssn: ''})
      }, [mobileOpen])

    if (onSubmit === undefined) {
        onSubmit = async (data) => {
            ssn = data["ssn"].replace('-','')
            var exists
            try {
                const response = await fetch(`${process.env.REACT_APP_FAST_API_URI}/patients/patient_exists/${ssn}`,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`,
                    },
                })
                console.log(response)
                if (!response.ok) {
                    throw new Error(`Error. Response code: ${response.status}`)
                }
                exists = await response.json()
                } catch (error) {
                    console.log("error:: ")
                    console.log(error)
                } 

                if (exists) {
                    navigate("/patient", {state: {patientSsn: ssn}})
                } else {
                    setPatientSsn(ssn)
                    setPopupState(true) // open add patient popup
                }
            reset({ssn: ''})
            if (handleCloseSearch) {
                handleCloseSearch() // close search popup when used
            }
            if (mobileOpen) {
                setMobileOpen(false)
            }
        }
    }

    return (
        <div className={divClassName ? divClassName : null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                    <TextFieldComponent
                        placeholder={t("components.general.SearchField.form.TextFieldComponent.placeholder")}
                        name="ssn"
                        type="text"
                        required={true}
                        pattern={/^(19|20)\d{6}[-]?\d{4}$/i}
                        patternMessage={t("components.general.SearchField.form.TextFieldComponent.patternMessage")}
                        // control, register, and errors must be sent in to TextFieldComponent
                        control={control}
                        register={register}
                        errors={errors}
                        disabled={disabled}
                        className={textFieldClassName ? textFieldClassName : null}
                        noHelperText={noHelperText ?? true}
                        endAdornment={<IconButton type="submit" aria-label="search">
                                        <SearchIcon className={iconClassName ? iconClassName : null} />
                                    </IconButton>}
                    />
                </div>
            </form>
        </div>
    );
}