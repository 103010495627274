import * as React from 'react'
import { FormHelperText } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


function DateTimePickerComponent(props) {
    // Date picker component
  
    // The parameter props includes:
    // - name: string, name of input field
    // - label: string, input label text to be displayed in the input field
    // - required: boolean, represents if the input field is required to submit form
    // - (Optional) requiredMessage: string, message displayed when value is missing from input field
    // - (Optional) defaultValue: date, dafault date for picker
    // - (Optional) birthDate: date, used to validate that value picked is note before birthdate
    // - control, register, errors

    const { t } = useTranslation()
    const name=props.name

    const dateTimeFormat = "yyyy-MM-dd HH:mm"
    const today = new Date()

    function validateDateTime(datetimeValue) {
        const d = moment(datetimeValue, dateTimeFormat, true);
        return d.isValid()
    }

    var birthDate
    if (props.birthDate === undefined) {
        birthDate = new Date(-8640000000000000) // The minimum date
    }
    else {
        birthDate = new Date(props.birthDate)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                defaultValue = {props.defaultValue? new Date(props.defaultValue) : null}
                control={props.control}
                {...props.register(name, 
                {
                    required: {
                        value: props.required,
                        message: props.requiredMessage ??  t("components.fields.required")
                    },
                    validate: {
                        rightInputFormat: value => validateDateTime(value) || t("components.fields.validate.rightInputFormat") + dateTimeFormat,
                        notInFuture: value => new Date(value) <= today.getTime() || t("components.fields.validate.notInFuture"),
                        notBeforeBirthDate: value => new Date(value) >= birthDate || t("components.fields.validate.notBeforeBirthDate")
                    }

                }
                )}
                ref={null}
                render={({ field }) => (
                    <DateTimePicker
                        {...field}
                        id="date-time-picker"
                        margin="normal"
                        inputVariant="outlined"
                        variant="inline"
                        ampm={false}
                        inputFormat={dateTimeFormat} /** "yyyy-MM-dd HH:mm" */
                        disableFuture={true}
                        disabled={props.disabled}
                        label={props.label}
                        renderInput={(params) => <TextField {...params} className="date-picker-textfield"/>}
                    />
                )}
            />
            <FormHelperText error={true}>{props.errors[name] ? props.errors[name].message : ""}</FormHelperText>
        </LocalizationProvider>
    )
}


export default DateTimePickerComponent