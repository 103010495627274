import { Button, IconButton } from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/Add';
import '../../styles/General.css'

function PrimaryButton({ id, label, component, to, state, type, onClick, extraClassName, disabled }) {
    return (
        <Button
            id={id ?? "primarybutton"}
            variant="contained" 
            size="large" 
            className={extraClassName ? `primary-button ${extraClassName}` : "primary-button"}
            component={component ?? null}
            to={to ?? null}
            state={state ?? null}
            type={type ?? null}
            onClick={onClick ?? null}
            disabled={disabled}
        >
            {label}
        </Button>
        )
}



function SecondaryButton({ id, label, component, to, state, type, onClick, red, extraClassName, disabled }) {

    var className 
    if (extraClassName && red) {
        className = `secondary-red-button ${extraClassName}`
    } else if (extraClassName) {
        className = `secondary-button ${extraClassName}`
    } else if (red) {
        className = "secondary-red-button"
    } else{
        className = "secondary-button"
    } 
    

    return (
        <Button
            id={id ?? "secondarybutton"}
            variant="outlined" 
            size="large" 
            className={className}
            component={component ?? null}
            to={to ?? null}
            state={state ?? null}
            type={type ?? null}
            onClick={onClick ?? null}
            disabled={disabled}
        >
            {label}
        </Button>
        )
}

function SimpleButton({ id, label, component, to, state, type, onClick, disabled }) {
    return (
        <Button
            id={id ?? "simplebutton"}
            variant="text" 
            size="large" 
            className="simple-button"
            component={component ?? null}
            to={to ?? null}
            state={state ?? null}
            type={type ?? null}
            onClick={onClick ?? null}
            disabled={disabled}
        >
            {label}
        </Button>
        )
}

function SlimButton({ id, label, component, to, state, type, onClick }) {
    return (
        <Button
            id={id ?? "slimbutton"}
            variant="text" 
            // size="large" 
            className="slim-button"
            component={component ?? null}
            to={to ?? null}
            state={state ?? null}
            type={type ?? null}
            onClick={onClick ?? null}
        >
            {label}
        </Button>
        )
}

function ArrowButton({ label, component, to, state }) {
    return (
    <Button 
        variant="text" 
        endIcon={<ArrowForwardIosSharpIcon fontSize="small"/> } 
        size="large" 
        disableRipple={true} 
        className="arrow-button"
        component={component}
        to={to}
        state={state}
    >
        {label}
    </Button>
    )
}

function AddButton({onClick, onMouseEnter, onMouseLeave}){
    return (
        <IconButton 
            className="add-button"
            onClick={onClick}
            disableRipple={true} 
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {<AddIcon sx={{fontSize:24}}/>}
        </IconButton>

    )
}

export { PrimaryButton, SecondaryButton, SimpleButton, SlimButton, ArrowButton, AddButton}