import React from "react"
import { InputAdornment, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import '../../styles/forms/Components.css'
import { useTranslation } from 'react-i18next'

export default function TextFieldComponent(props) {
    // TextField component
  
    // The parameter props includes:
    // - name: string, name of input field
    // - (Optional) required: boolean, represents if the input field is required to submit form
    // - (Optional) requiredMessage: string, message displayed when value is missing from 
    //      input field
    // - (Optional) pattern: string, regex to validate input
    // - (Optional) patternMessage: string, message displayed when regex does not accept
    //      input value
    // - (Optional) max: integer, maximum numerical value the input can be
    // - (Optional) maxMessage: string, message displayed when input value is greater than max
    // - (Optional) min: integer, minimum numerical value the input can be
    // - (Optional) minMessage: string, message displayed when input value is lesser than min
    // - (Optional) maxLength: integer, maximum length of input value
    // - (Optional) maxLengthMessage: string, message displayed when length of input value is
    //      greater than maxLength
    // - (Optional) label: string, input label text to be displayed in the input field
    // - type: string, what data type is expected of input value
    // - (Optional) startAdornment: string, decoration for input field positioned at the beginning
    // - (Optional) endAdornment: string, decoration for input field positioned at the end
    // - (Optional) className: string
    // - (Optional) noHelperText: boolean
    // - (Optional) placeholder: string
    // - (Optional) warningCheck: func, callable object to check if value inputted should display
    //      a warning
    // - (Optional) warning: string, warning text that appears in the helper text if there is a warning
    // - control, register, errors

    const { t } = useTranslation()
    const name=props.name
    return (
        <Controller
            name={name}
            control={props.control}
            {...props.register(name,
                {
                    required: {
                        value: props.required,
                        message: props.requiredMessage ?? t("components.fields.required")
                    },
                    pattern: {
                        value: props.pattern,
                        message: props.patternMessage ?? t("components.fields.pattern")
                    },
                    max: {
                        value: props.max,
                        message: props.maxMessage ?? t("components.fields.max")
                    },
                    min: {
                        value: props.min,
                        message: props.minMessage ?? t("components.fields.min")
                    },
                    maxLength: {
                        value: props.maxLength,
                        message: props.maxLengthMessage ?? t("components.fields.maxLength")
                    }
                })
            }
            ref={props.register}
            render={({ field }) => {
                return <TextField
                    {...field}
                    type={props.type}
                    label={props.label}
                    error={!!props.errors[name]}
                    variant="outlined"
                    disabled={props.disabled}
                    InputProps={{
                        startAdornment: props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : "",
                        endAdornment: props.endAdornment ? <InputAdornment position="end">{props.endAdornment}</InputAdornment> : "",
                    }}
                    onChange={(event) => {
                        if (props.warningCheck !== undefined) {
                            props.warningCheck(event.target.value)
                        }
                        if (props.type === "text") return field.onChange(event.target.value)
                        else if (event.target.value < 0) return field.onChange(Number(event.target.value))
                        else return field.onChange(+event.target.value)
                    }}
                    helperText={
                        props.noHelperText ? null : (
                            props.errors[name] ? props.errors[name].message : props.warning
                        ) 
                    }
                    className={props.className ?? "textfield" }
                    placeholder={props.placeholder ?? null }
                    fullWidth={true}
                />
            }
            }
        />
    )
}
