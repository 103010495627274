import React, { useState } from 'react'
import SearchField from "../general/SearchField"
import AddPatientPopUp from '../addPatient/AddPatientPopUp'

export default function NavigationSearchFieldMobile({ accessToken, divClassName, mobileOpen, setMobileOpen }) {
    // Navigation search field for mobile version

    // The Parameter props includes:
    // - accessToken
    // - divClassName: string, classname for wrapping div
    // - openMobile: boolean, determines if mobile navigation is open
    // - setMobileOpen: func, set openMobile


    const [popupState, setPopupState] = useState(false) // Patient not exist popUp
    const [patientSsn, setPatientSsn] = useState('')


    return (
        <div className={divClassName ? divClassName : null}>
            <SearchField 
            accessToken={accessToken} 
            divClassName="navigation-search-field-mobile"
            popupState={popupState}
            setPopupState={setPopupState}
            setPatientSsn={setPatientSsn}
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
            />
            {popupState && <AddPatientPopUp 
                    popupState={popupState}
                    setPopupState={setPopupState}
                    patientSsn={patientSsn}
            />}
        </div>
    )
}