import React from "react"
import {
  FormControl,
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel
} from '@mui/material'
import { Controller } from 'react-hook-form'
import "../../styles/forms/Components.css"
import { useTranslation } from 'react-i18next'


export default function RadioButtonComponent(props) {
    // Radio button component
  
    // The parameter props includes:
    // - name: string, name of input field
    // - label: string, input label text to be displayed in the input field
    // - required: boolean, represents if the input field is required to submit form
    // - (Optional) requiredMessage: string, message displayed when value is missing from input field
    // - items: array, a list of options of type object to choose from
    // - (Optional) warningCheck: func, callable object to check if value inputted should display
    // - (Optional) warning: string, warning text that appears in the helper text if there is a warning
    //      a warning
    // - control, register, errors

    const { t } = useTranslation()
    const name=props.name
    return (
        <FormControl>
            <Controller
                name={name}
                control={props.control}
                {...props.register(name, 
                {
                    required: {
                        value: props.required,
                        message: props.requiredMessage ?? t("components.fields.required")
                    }
                }
                )}
                defaultValue = {props.defaultValue ?? ""}
                ref={null}
                render={({ field }) => (
                    <RadioGroup
                        {...field}
                        row
                        disabled={props.disabled}
                        onChange={(event) => {
                            if (props.warningCheck !== undefined) {
                                props.warningCheck(event.target.value)
                            }
                            return field.onChange(event.target.value)
                        }}
                    >
                        {props.items.map((item) => (
                            <FormControlLabel 
                                className="radio"
                                key={item.id} 
                                value={item.id} 
                                control={<Radio disabled={props.disabled}/>} 
                                label={item.description} 
                            />
                        ))}
                    </RadioGroup>
                )}
            />
            <FormHelperText error={props.warning === "" ? true : false}>
                 {props.errors[name] ? props.errors[name].message : props.warning}
            </FormHelperText>
        </FormControl>
    )
}
