import * as React from 'react'
import {
  FormControl,
  Switch
} from '@mui/material'
import { Controller } from 'react-hook-form'





function SwitchComponent(props) {
  // Switch component where the user can select between true or false

  // The Parameter props includes:
  // - name: string, name of input field
  // - defaultValue: boolean, default value of field
  // - control

  return (
    <FormControl>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue ?? false}
        render={({ field }) => (
          <Switch 
            {...field}
            checked={field.value}
            onChange={(_, data) => field.onChange(data)} 
            className="switch"
            disabled={props.disabled}
          />
        )}
      />
    </FormControl>
  )
}


export default SwitchComponent
