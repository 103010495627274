import * as React from 'react'
import { FormHelperText } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import '../../styles/forms/Form.css'
import "../../styles/forms/Components.css"
import moment from 'moment'
import { useTranslation } from 'react-i18next'


function DatePickerComponent(props) {
    // Date picker component
  
    // The parameter props includes:
    // - name: string, name of input field
    // - (Optional) label: string, input label text to be displayed in the input field
    // - required: boolean, represents if the input field is required to submit form
    // - (Optional) requiredMessage: string, message displayed when value is missing from input field
    // - (Optional) defaultValue: date, dafault date for picker
    // - (Optional) readOnly: boolean, if the date should be read only or not
    // - (Optional) inFutureMessage: string, message displayed when date is in 
    // - (Optional) placeholder: string
    // - (Optional) birthDate: date, used to validate that value picked is note before birthdate
    // - control, register, errors

    const { t } = useTranslation()
    const name=props.name

    const today = new Date()
    var birthDate
    if (props.birthDate === undefined) {
        birthDate = new Date(-8640000000000000) // The minimum date
    }
    else {
        birthDate = new Date(props.birthDate)
    }

    const dateFormat = "yyyy-MM-dd"

    function validateDate(datetimeValue) {
        const d = moment(datetimeValue, dateFormat, true);
        return d.isValid()
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                defaultValue = {props.defaultValue? new Date(props.defaultValue) : null}
                control={props.control}
                {...props.register(name, 
                {
                    required: {
                        value: props.required,
                        message: props.requiredMessage ?? t("components.fields.required")
                    },
                    validate: {
                        rightInputFormat: value => validateDate(value) || t("components.fields.validate.rightInputFormat") + dateFormat,
                        notInFuture: value => new Date(value) <= today.getTime() || t("components.fields.validate.notInFuture"),
                        notBeforeBirthDate: value => new Date(value) >= birthDate || t("components.fields.validate.notBeforeBirthDate")
                    }
                }
                )}
                ref={null}
                render={({ field }) => (
                    <DesktopDatePicker
                        {...field}
                        id="date-picker"
                        margin="normal"
                        inputVariant="outlined"
                        variant="inline"
                        inputFormat={dateFormat}
                        disableFuture={true}
                        disabled={props.disabled}
                        label={props.label ?? null}
                        renderInput={(params) => <TextField 
                            {...params} 
                            inputProps={{
                                ...params.inputProps,
                                placeholder: props.placeholder ?? props.label
                            }}
                            className="date-picker-textfield" 
                        />}
                        readOnly={props.readOnly ? props.readOnly : false}
                    />
                )}
            />
            <FormHelperText error={true}>{props.errors[name] ? props.errors[name].message : ""}</FormHelperText>
        </LocalizationProvider>
    )
}


export default DatePickerComponent